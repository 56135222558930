import React , { useState } from "react";
import { Link, useHistory } from "react-router-dom"
import './CategoryMenu.css'
import CategoryItem from "./CategoryItem";
import { goToTop, truncateString } from "../../utils";

import PhotoDisplayHandler from "../general/PhotoDisplayHandler";

const MenuItem = ({ category, categories, hideDropdown }) => {
    const history = useHistory()
    const [visibilityDropMenu, setVisibilityDropMenu] = useState(true)

    const showCategory = (category_key) => {
        document.getElementsByClassName('.category_' + category_key)["hidden"] = false;
        setVisibilityDropMenu(false)
    }
    const hideCategory = (category_key) => {
        document.getElementsByClassName('.category_' + category_key)["hidden"] = true;
        setVisibilityDropMenu(true)
    }

    return (
        <li className="product_menu_drop_menu_item"
            key={"category_" + category.pc_name}
            onMouseEnter={a => showCategory(category.pc_name)}
            onMouseLeave={a => hideCategory(category.pc_name)}
        >

            <div
                onClick={() => {
                    goToTop()
                    history.push(`/proizvodi/${category.slug}`)
                    hideDropdown()
                }}>
                <PhotoDisplayHandler
                    img_name={category.img_name}
                    dir="categories"
                    calculate={false}
                    img_class_name="product_type_page_item_box_image_menu"
                    img_alt={category.pc_name}
                    width='25px'
                    height='25px'
                    
                />
            </div>


            <Link
                to={"/proizvodi/" + category.slug}
                key={"category_" + category.pc_name}
                className="product_menu_drop_menu_item_text"
                onClick={() => {
                    goToTop()
                    hideDropdown()
                }}

            >{truncateString(category.pc_name, 40)}</Link>

            <div className={"product_menu_drop_menu_subitem category_" + category.pc_name} hidden={visibilityDropMenu}>
                <CategoryItem
                    category={category}
                    categories={categories}
                    hideDropdown={hideDropdown}
                />

            </div>
        </li>
    );
}

export default MenuItem