import { take, put, call } from 'redux-saga/effects';

import { FETCH_STORE_INFO } from './constants';
import { putStoreInfo } from './actions'
import apiCall, { API_PATHS } from '../../services/ApiCall';

export function* fetchStoreInfoFunction() {
    const { payload } = yield take(FETCH_STORE_INFO);

    yield put(putStoreInfo({ 'isFetch': false }));
    const data = yield call(apiCall, 'GET', API_PATHS.API_STORE_INFO, payload);
    yield put(putStoreInfo({
        'data': data.data.data,
        'isFetch': true
    }));
}