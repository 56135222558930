import axiosCustom from "./HttpBaseClient";
export const API_PATHS = {
    API_CART: '/cart',                      //GET
    API_STORE_INFO: '/store/1/info',        //GET
    API_LOGIN: '/login',                    //POST
    API_SIGN_OUT: '/sign-out',              //GET
    API_CATEGORIES: '/product_category',    //GET
    API_SLIDER: '/slider',                  //GET

}

// GENERICKA FUKCIJA ZA GET I POST ZAHTEVE
// import apiCall, { API_PATHS } from '../../services/ApiCall';
// PRIMER POZIVA call(apiCall, 'GET', API_PATHS.API_STORE_INFO, payload);
const apiCall = async (type, path, payload) => {
    if (type === 'GET') {
        try {
            const { data, status } = await axiosCustom.get(path, payload)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    throw error;
                });
            return { data, status };
        } catch (error) {
            throw error;
        }
    } else if (type === 'POST') {
        try {
            const { data, status } = await axiosCustom.post(path, payload)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    throw error;
                });
            return { data, status };
        } catch (error) {
            throw error;
        }
    }else {
        // console.log("Ne postoji taj tip zahteva!")
    }

}
export default apiCall; 