import React from "react";
import { useSelector } from 'react-redux';
import { Col, Row, Image } from 'antd';
import { FacebookFilled, InstagramOutlined } from '@ant-design/icons';
// TODO add key
import GoogleMapReact from 'google-map-react'

import './Contact.css'
import { AppBreadcrumb } from "../my_components";
import { storeInfoSelector } from "../../store/my_selectors"
import { getBusinessHours } from "../../utils";

const Contact = () => {
  const storeInfo = useSelector(storeInfoSelector);
  const isFetch = storeInfo.isFetch;
  const defaultProps = {
    center: {
      lat: 44.7547181301373,
      lng: 19.217015131257188
    },
    zoom: 15
  };

  return (

    <Row justify="center" >

      <Col
        // className="category_page_list"
        xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
        md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
        xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
      >
        <Row className="breadcrumb_path">
          <AppBreadcrumb />
        </Row>
        <Row style={{ height: '300px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            {/* potreban je google  geocoding za vracanje lat, lng od adrese, placa se */}
            <Image
              lat={44.75419667838826}
              lng={19.21598910665077}
              src={require(`../../assets/MiniIcons/map-point.png`).default}
              width={'60px'}
              height={'60px'}
              style={{ marginTop: '-60px', marginLeft: '-60px' }}

              preview={false}
            />
          </GoogleMapReact>
        </Row>
        <Row className="contact_page_box">

          <Col className="contact_page_box_col">
            <Row>
              {isFetch &&
                <Col className="contact_page_box_1">
                  <Row className="contact_page_box_title">Kontakt</Row>
                  <Row >
                    <Col className="contact_page_box_11">
                      <Row className="contact_page_box_subtitle">Brojevi telefona:</Row>
                      {/* <Row className="contact_page_box_subtitle">Fizicka lica</Row> */}
                      {/* <Row className="contact_page_box_subtitle">Pravna lica</Row> */}
                      {storeInfo.data.telephone &&
                        <>
                          {storeInfo.data.telephone.map((x, y) =>
                            <Row key={y}>
                              <a href={"tel:" + x.tel_num} className="contact_page_box_item">{x.owner} | {x.tel_num}</a>
                              <br />
                            </Row>
                          )}

                        </>
                      }
                      <Row className="contact_page_box_subtitle">Email: </Row>
                      <Row >
                        {storeInfo.data.emails && storeInfo.data.emails.map((x, y) =>
                          <div key={y}>
                            <a href={"mailto:" + x.email} className="contact_page_box_item">{x.owner} | {x.email}</a>
                            <br />
                          </div>
                        )
                        }
                      </Row>
                      <Row className="contact_page_box_subtitle">Socijalne mreže: </Row>
                      <Row>
                        <a href="https://www.facebook.com/infocomp1/" className="contact_page_box_item"><FacebookFilled style={{ paddingRight: '10px' }} /></a>

                        <a href="https://www.instagram.com/infocomp_doo/?hl=en" className="contact_page_box_item"><InstagramOutlined /></a>
                      </Row>

                    </Col>
                    <Col className="contact_page_box_12">
                      <Row className="contact_page_box_subtitle">Radno vrijeme:</Row>
                      {
                        storeInfo.data.business_hours &&
                        storeInfo.data.business_hours.map((x, y) =>
                          <Row className="contact_page_box_item" key={y}>
                            {getBusinessHours(x)}
                          </Row>
                        )

                      }
                    </Col>
                  </Row>

                </Col>
              }
              {/* <Col className="contact_page_box_2">
                <Row className="contact_page_box_title">Pošaljite nam poruku</Row>
                <Row>IMPLEMENTIRATI</Row>
              </Col> */}
            </Row>
          </Col>
        </Row>



      </Col>
    </Row>
  );
}

export default Contact;
