import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom"
import { Row, Col, Collapse, Image, Drawer, } from "antd";

import { RightOutlined, LeftOutlined, } from '@ant-design/icons';

import '../header/AppHeader.css'
import './CategoryMenu.css'
import '../../styles/icons.css'

import { goToTop } from "../../utils";
import PhotoDisplayHandler from "../general/PhotoDisplayHandler";

const { Panel } = Collapse;



const MenuMobileChild = ({ category, onClose }) => {
    const history = useHistory()
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };


    return (
        <Row key={category.pc_name}>
            <Col style={{ width: '20%' }}>
                <PhotoDisplayHandler
                    img_name={category.img_name}
                    dir="categories"
                    calculate={false}
                    // img_class_name="logo_div_menu"
                    img_alt={category.pc_name}
                    width='24px'
                    height='24px'

                />
            </Col>
            <Col style={{ width: '70%' }} className="mobile_menu_category">
                <p onClick={showChildrenDrawer} className="mobile_menu_link">{category.pc_name}</p>
            </Col>
            <Col style={{ width: '10%' }}><RightOutlined /></Col>

            <Drawer
                title={<div className="logo_link_menu">
                    <img
                        className="logo_div_menu"
                        src={require(`../../assets/Logo/mobilni-logo.svg`).default}
                    />
                </div>}
                width={300}
                placement={'left'}
                closable={true}
                onClose={onChildrenDrawerClose}
                visible={childrenDrawer}
                key={category.pc_name}
                
            >
                <Row style={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <Col style={{ width: '15%'}}><LeftOutlined onClick={onChildrenDrawerClose} /></Col>
                    <Col style={{ width: '85%' }} onClick={()=> {
                        onChildrenDrawerClose();
                        onClose();
                    }}><a className="mobile_menu_drawer2_title" href={`/proizvodi/${category.slug}`}>{category.pc_name}</a></Col>
                </Row>

                {
                    category.children && category.children.map((subcategory, key) =>
                        <>
                            <Collapse accordion expandIconPosition='right' style={{ width: '100%', border: 'none', backgroundColor: 'transparent', 
                            marginTop: '0px', marginBottom: '0px'}}>
                                {subcategory.children.length != 0 ?
                                    <Panel header={<Row style={{display: 'flex' , alignItems: 'start', justifyContent: 'space-between', backgroundColor: 'transparent'}}>
                                        <Col style={{ width: '15%'}}>
                                            <PhotoDisplayHandler
                                                img_name={subcategory.img_name}
                                                dir="categories"
                                                calculate={false}
                                                img_alt={subcategory.pc_name}
                                                width='24px'
                                                height='24px'

                                            />
                                        </Col>
                                        <Col style={{ width: '80%'}}><p className="mobile_menu_link">{subcategory.pc_name}</p></Col>
                                    </Row>}
                                        key="1"
                                        style={{ borderBottom: '0px ' }}
                                    >
                                        {
                                            subcategory.children != 0 &&
                                            < >
                                                {
                                                    subcategory.children.map((product_type, type_id) =>
                                                        <Row style={{ marginLeft: '0', marginTop: '3px', marginBottom: '3px', backgroundColor: 'transparent'}}
                                                            onClick={() => {
                                                                goToTop()
                                                                history.push(`/proizvodi/${category.slug}/${subcategory.slug}/${product_type.slug}`)
                                                                onChildrenDrawerClose()
                                                                onClose()
                                                                
                                                            }}
                                                        >
                                                            <Col style={{ width: '15%' }}>
                                                                <PhotoDisplayHandler
                                                                    img_name={product_type.img_name}
                                                                    dir="product_types"
                                                                    calculate={false}
                                                                    img_alt={product_type.pt_name}
                                                                    width='24px'
                                                                    height='24px'

                                                                />
                                                            </Col>
                                                            <Col style={{ width: '75%', fontSize: '24px', alignContent: 'center' }}><p className="mobile_menu_link">{product_type.pt_name}</p></Col>
                                                        </Row>)
                                                }
                                            </>
                                        }

                                    </Panel>
                                    :
                                    <Row onClick={() => {
                                        goToTop()
                                        if (subcategory.children.length == 0)
                                            history.push(`/proizvodi/${category.slug}/${subcategory.slug}/pregled`)
                                        else
                                            history.push(`/proizvodi/${category.slug}/${subcategory.slug}`)
                                        onChildrenDrawerClose()
                                        onClose()

                                    }}
                                        style={{
                                            width: '100%', marginLeft: '6%', border: 'none',
                                             display: 'flex', flexDirection: 'row', backgroundColor: 'transparent'
                                        }}>

                                        <Col style={{ width: '15%', alignSelf: 'start'}}>
                                            <PhotoDisplayHandler
                                                img_name={subcategory.img_name}
                                                dir="categories"
                                                calculate={false}
                                                img_alt={subcategory.pc_name}
                                                width='24px'
                                                height='24px'

                                            />
                                        </Col>
                                        <Col style={{ width: '75%', fontSize: '24px', alignContent: 'center' }}><p className="mobile_menu_link">{subcategory.pc_name}</p></Col>

                                    </Row>


                                }
                            </Collapse>


                        </>

                    )
                }
                <Row>



                </Row>

            </Drawer >
        </Row >
    );
}

export default MenuMobileChild