import {
    PUT_TO_DEVICE_DATA,
} from '../constants';

import * as computationFunctions from './computation-functions';

const initialState = {
    deviceData: {
        device: "computer",
        orientation: "desktop",
        isFetch: true
    }
};

const auxilliaryVariablesReducer = (state = initialState, { type, payload }) => {
    if (actionHandler.hasOwnProperty(type)) {
        return actionHandler[type](state, payload);
    }
    return state;
};

const actionHandler = {
    [PUT_TO_DEVICE_DATA]: computationFunctions.putToDeviceData
};

export default auxilliaryVariablesReducer;