import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import { fetchPost } from "../../store/post/actions"
import { postSelector } from '../../store/my_selectors'
import PostsAndPartnersComponent from "./PostsAndPartnersComponent";
import './Post.css';

// OBJAVE I PARTNERI 
const PostsAndPartners = () => {
  const location = useLocation();
  const current = location.pathname.split("/")[1];
  const dispatch = useDispatch();

  const posts = useSelector(postSelector);
  const isFetchPosts = posts.isFetch;

  const [pageSize, setpageSize] = useState(24)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    dispatch(
      fetchPost({
        params: {
          page: currentPage,
          size: pageSize,
          pot: current === "objave" ? "Vijest" : "Partner"
        }
      })
    );
  }, [currentPage, pageSize, current]);


  const onShowSizeChange = (page, ps) => {
    setCurrentPage(page)
    setpageSize(ps)
  }

  return (
    <PostsAndPartnersComponent
      current={current}
      list={posts.data}
      isFetch={isFetchPosts}
      pageSize={pageSize}
      currentPage={currentPage}
      onShowSizeChange={onShowSizeChange}
    />
  );
}

export default PostsAndPartners;
