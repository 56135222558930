import {
    PUT_HOME_PRODUCTS,
    PUT_PAG_PRODUCTS,
    PUT_PAG_PRODUCTS_BY_PT_NAME,
    PUT_PRODUCT_DETAIL
} from '../constants'

import * as computationFunctions from './computation-functions'

const initialState = {
    pag_products_pt_name: {
        data: [],
        isFetch: false
    },
    pag_products: {
        data: [],
        isFetch: false
    },
    home_products: {
        data: [],
        isFetch: false
    },
    product_detail: {
        data: [],
        isFetch: false
    }
}

const productsReducer = (state = initialState, {type, payload}) => {
    if (actionHandler.hasOwnProperty(type)){
        return actionHandler[type](state, payload)
    }
    return state
}

const actionHandler = {
    [PUT_PAG_PRODUCTS_BY_PT_NAME]: computationFunctions.putPagProductsPtName,
    [PUT_PAG_PRODUCTS]: computationFunctions.putPagProducts,
    [PUT_HOME_PRODUCTS]: computationFunctions.putHomeProducts,
    [PUT_PRODUCT_DETAIL]: computationFunctions.putProductDetail,
}

export default productsReducer;