import {
    PUT_STORE_INFO,
    FETCH_STORE_INFO
} from './constants';

//end users
export const fetchStoreInfo = payload => ({
    type: FETCH_STORE_INFO,
    payload
});

export const putStoreInfo = payload => ({
    type: PUT_STORE_INFO,
    payload
});

