import React from "react"
import { Row } from "antd"
import BanersCard from "../card/BanersCard";

import './Home.css';

const Baners = () => {

    return (
        <Row className="baners_component">
            {/* TODO : NAPRAVITI DA NIJE STATICKO, NAPRAVITI MOZDA DA BUDE KAO SLIDER Carousel*/}
            <BanersCard title="KETRIDŽI I TONERI" description="Generalni distributer za BiH i Srbiju" picture="news1.png" />
            <BanersCard title="MI IMAMO RATU ZA SVAKU PLATU" description="0% kamata, 0% obrade kredita, 0% ostali troškovi" picture="news2.png" />
            <BanersCard title="27. MART br. 13 BIJELJINA 76300" description="Posjetite nas u našem maloprodajnom objektu" picture="news3.png"/>         
        </Row>
    );
}

export default Baners;