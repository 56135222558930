import {
    PUT_SLIDER
} from '../constants';

import * as computationFunctions from './computation-functions';

const initialState = {
    slider: {
        data: [],
        isFetch: false
    },
};

const sliderReducer = (state = initialState, { type, payload }) => {
    if (actionHandler.hasOwnProperty(type)) {
        return actionHandler[type](state, payload);
    }
    return state;
};

const actionHandler = {
    [PUT_SLIDER]: computationFunctions.putSlider,
};

export default sliderReducer;