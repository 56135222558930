//paginated products by product type name
export const FETCH_PAG_PRODUCTS_BY_PT_NAME = 'fetch_pag_products_by_pt_name';
export const PUT_PAG_PRODUCTS_BY_PT_NAME = 'put_pag_products_by_pt_name';

//paginated products 
export const FETCH_PAG_PRODUCTS = 'fetch_pag_products';
export const PUT_PAG_PRODUCTS = 'put_pag_products';

// home page > 12 products
export const FETCH_HOME_PRODUCTS = 'fetch_home_products';
export const PUT_HOME_PRODUCTS = 'put_home_products';

// product by id 
export const FETCH_PRODUCT_DETAIL = 'fetch_product_detail';
export const PUT_PRODUCT_DETAIL = 'put_product_detail'