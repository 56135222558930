import {
    FETCH_PAG_PRODUCTS_BY_PT_NAME,
    FETCH_PAG_PRODUCTS,
    FETCH_HOME_PRODUCTS,
    PUT_PAG_PRODUCTS_BY_PT_NAME,
    PUT_PAG_PRODUCTS,
    PUT_HOME_PRODUCTS,
    FETCH_PRODUCT_DETAIL,
    PUT_PRODUCT_DETAIL
} from './constants';

// FETCH
export const fetchPagProductsByPtName = payload => ({
    type: FETCH_PAG_PRODUCTS_BY_PT_NAME,
    payload
})
export const fetchPagProducts = payload => ({
    type: FETCH_PAG_PRODUCTS,
    payload
})
export const fetchHomeProducts = payload => ({
    type: FETCH_HOME_PRODUCTS,
    payload
})
export const fetchProductDetailById = payload => ({
    type: FETCH_PRODUCT_DETAIL,
    payload
})

// PUT
export const putPagProductsByPtName = payload => ({
    type: PUT_PAG_PRODUCTS_BY_PT_NAME,
    payload
})
export const putPagProducts = payload => ({
    type: PUT_PAG_PRODUCTS,
    payload
})
export const putHomeProducts = payload => ({
    type: PUT_HOME_PRODUCTS,
    payload
})
export const putProductDetail = payload => ({
    type: PUT_PRODUCT_DETAIL,
    payload
})