import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, Switch } from "react-router-dom";
import { Layout } from "antd";
import './App.css';

import { Home, Contact, Profile, Login, Registration, ProductsView, ProductDetails, 
    CategoryPage, SubCategoryPage, ProductTypePage, Cart, Shopping, 
    PostAndPartnerCardDetail, PostsAndPartners} from '../my_components'

import { putToDeviceDataAction } from "../../store/auxilliaryVariables/actions";


const { Content } = Layout;

const AppContent = () => {
    const dispatch = useDispatch();

    const handleResize = () => {
        const device = !!navigator.maxTouchPoints ? 'mobile' : 'computer';
        const orientation = !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
        dispatch(putToDeviceDataAction({ device: device, orientation: orientation }))
    }

    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleResize)
        window.addEventListener("click", handleResize)
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("DOMContentLoaded", handleResize)
            window.removeEventListener("click", handleResize)
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <Content className="app_content" >
            <Switch>
                <Route path='/prijava' component={Login} />
                <Route path='/registracija' component={Registration} />
                {/* <Route path='/nacin-placanja' component={PaymentMethods} /> */}
                {/* <Route path='/politika-privatnosti' component={PrivatePolicy} /> */}
                <Route path='/profil' component={Profile} />
                <Route path='/korpa' component={Cart} />
                <Route path='/kupovina' component={Shopping} />

                <Route exact path='/' component={Home} />
                <Route exact path='/proizvodi' component={CategoryPage} />

                <Route exact path='/proizvodi/:category' component={SubCategoryPage} />
                <Route exact path='/proizvodi/:category/:subcategory' component={ProductTypePage} />
                <Route exact path='/proizvodi/:category/:subcategory/pregled' component={ProductsView} />
                <Route exact path='/proizvodi/:category/:subcategory/:product_type' component={ProductsView} />
                <Route path="/proizvod/:product_name" exact component={ProductDetails} />

                <Route exact path='/objave' component={PostsAndPartners} />
                <Route exact path='/objave/:slug' component={PostAndPartnerCardDetail} />

                <Route exact path='/partneri' component={PostsAndPartners} />
                <Route exact path='/partneri/:slug' component={PostAndPartnerCardDetail} />

                <Route path='/kontakt' component={Contact} />
                {/* <Route path='/akcije' component={Promotions} /> */}
                {/* <Route path='/katalog' component={Catalog} /> */}


                {/* TODO: DORADITI NOT FOUND STRANICU */}
                <Redirect from="*" to="/" />
                {/* <Route component={NotFound} /> */}
            </Switch>
        </Content>

    );
}

export default AppContent;
