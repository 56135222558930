export function putToDeviceData(state, payload) {
    // console.log('device: ' + payload.device + ", orientation: " +  payload.orientation)
    return {
        ...state,
        deviceData: {
            device: payload.device,
            orientation: payload.orientation,
            isFetch: true
        }
    };
}
