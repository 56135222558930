import React from "react";
import { Col, Row, Pagination } from 'antd';

import { AppBreadcrumb, PostAndPartnerCard } from '../my_components'
import './Post.css';

const PostsAndPartnersComponent = ({ list, isFetch, pageSize, currentPage, onShowSizeChange, current }) => {

  return (
    <Row justify="center" >

      <Col
        xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
        md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
        xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
      >
        <Row className="breadcrumb_path">
          <AppBreadcrumb />
        </Row>

        <Row className="post_components">

          {
            isFetch && list.data && list.data.map((item, key) =>
              <PostAndPartnerCard item={item} key={key} current={current}/>
            )
          }
        </Row>
        {
          isFetch && list.data &&
          <Row
            className="post_view_pagination"
          >
            <Col>
              <Pagination
                defaultCurrent={1}
                pageSize={pageSize}
                current={currentPage}
                total={isFetch ? list.totalItems : 0}
                onChange={onShowSizeChange}
                showSizeChanger
                // showQuickJumper
                onShowSizeChange={onShowSizeChange}
                pageSizeOptions={[12, 24, 52, 100]}

              />
            </Col>

          </Row>
        }

      </Col>
    </Row>
  );
}

export default PostsAndPartnersComponent;
