import React, { useEffect, useState } from "react";
import { Row, Col, Menu, Button, Image } from "antd";
import { Link, useHistory } from "react-router-dom"
import { goToTop, truncateString } from "../../utils";
import './CategoryMenu.css'
import PhotoDisplayHandler from "../general/PhotoDisplayHandler";

const CategoryItem = ({ category, categories, hideDropdown }) => {
    const history = useHistory();
    const [isSmallScreen, setIsSmallScreen] = useState(false)
    const handleResize = () => {
        if (window.innerWidth <= 1600) {
            setIsSmallScreen(true)
        } else {
            setIsSmallScreen(false)
        }
    }
    useEffect(() => {
        window.addEventListener("DOMContentLoaded", handleResize)
        window.addEventListener("click", handleResize)
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("DOMContentLoaded", handleResize)
            window.removeEventListener("click", handleResize)
            window.removeEventListener("resize", handleResize)
        }
    }, [])
    const menuSize = (categories.length * 50).toString() + "px"
    const mobileMenuSize = (categories.length * 40).toString() + "px"

    return (
        <Row>
            <Col className="category_item_box" style={{ height: isSmallScreen ? `${mobileMenuSize}` : `${menuSize}` }}>
                {
                    category.children.map((subcategory, key) =>
                        <Button
                            key={subcategory.pc_name}
                            className="category_item_box_button"
                        >
                            <Row>
                                <Col className="category_item_box_button_item" >
                                    <div
                                        onClick={() => {
                                            goToTop()
                                            hideDropdown()
                                            if (subcategory.children.length == 0)
                                                history.push(`/proizvodi/${category.slug}/${subcategory.slug}/pregled`)
                                            else
                                                history.push(`/proizvodi/${category.slug}/${subcategory.slug}`)
                                        }}
                                    >
                                        <PhotoDisplayHandler
                                            img_name={subcategory.img_name}
                                            dir="categories"
                                            calculate={false}
                                            img_class_name="category_item_box_image"
                                            img_alt={subcategory.pc_name}
                                            width='25px'
                                            height='25px'

                                        />
                                    </div>
                                </Col>
                                <Col className="category_item_box_button_item2">
                                    <Row
                                        className="category_item_box_title"
                                        onClick={() => {
                                            goToTop()
                                            hideDropdown()
                                            if (subcategory.children.length == 0)
                                                history.push(`/proizvodi/${category.slug}/${subcategory.slug}/pregled`)
                                            else
                                                history.push(`/proizvodi/${category.slug}/${subcategory.slug}`)
                                        }}
                                    >{truncateString(subcategory.pc_name, 40)}</Row>
                                    <Row className="category_item_box_product_types_links">
                                        {subcategory.children && subcategory.children.map((product_type, key) =>
                                            <Link
                                                to={"/proizvodi/" + category.slug + "/" + subcategory.slug + "/" + product_type.slug}
                                                key={product_type.pt_name}
                                                className="category_item_box_links"
                                                onClick={() => {
                                                    goToTop()
                                                    hideDropdown()
                                                }}
                                            >{product_type.pt_name}   </Link>
                                        )}
                                    </Row>
                                </Col>



                            </Row>
                        </Button>
                    )
                }
            </Col>
        </Row>


    );
}

export default CategoryItem