import {
    PUT_INDIVIDUAL_POST,
    PUT_POST
} from '../constants';

import * as computationFunctions from './computation-functions';

const initialState = {
    post: {
        data: [],
        isFetch: false
    },
    individualPost: {
        data: [],
        isFetch: false
    }
};

const newsReducer = (state = initialState, { type, payload }) => {
    if (actionHandler.hasOwnProperty(type)) {
        return actionHandler[type](state, payload);
    }
    return state;
};

const actionHandler = {
    [PUT_POST]: computationFunctions.putPost,
    [PUT_INDIVIDUAL_POST]: computationFunctions.putIndividualPost
};

export default newsReducer;