import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { Layout, Row, Col } from "antd";
import { FacebookFilled, InstagramOutlined } from '@ant-design/icons';

import './AppFooter.css'
import '../../styles/icons.css'

import { storeInfoSelector } from "../../store/my_selectors"
import { fetchStoreInfo } from "../../store/storeInfo/actions"
import { getBusinessHours, goToTop } from "../../utils";

const { Footer } = Layout

const AppFooter = () => {

    const dispatch = useDispatch();
    const storeInfo = useSelector(storeInfoSelector);
    const isFetch = storeInfo.isFetch;

    useEffect(() => {
        if (isFetch === false) {
            dispatch(
                fetchStoreInfo()
            );
        }
    }, []);

    return (
        <Footer className="footer" >

            {/* NAKNADNO CE BITI OVO     */}
            {/* <Row className="footer1">
                
                <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} 
                md={{ span: 9, offset: 3 }} lg={{ span: 9, offset: 3 }} xl={{ span: 9, offset: 3}} xxl={{span: 10, offset: 3}}>

                    <Row >
                        <Col>
                            <div className="letter_footer1_icon" />
                        </Col>
                        <Col className="footer1_col">
                            <h3 className="footer1_title">NEWSLETTER</h3>
                            <h4 className="footer1_text">Saznajte sve najnovije informacije o događajima,<br /> prodaji i ponudama. Prijavite se za bilten danas.</h4>
                        </Col>
                    </Row>

                </Col>
                <Col xs={{ span: 20, offset: 2 }} sm={{ span: 20, offset: 2 }} 
                md={{ span: 10, offset: 0 }} lg={{ span: 10, offset: 0 }} xl={{ span: 10, offset: 0}} xxl={{span: 10, offset: 0}}>
                    <Input className="footer1_input" placeholder="Unesite email adresu" />
                    <Button className="footer1_button" >PRIJAVI SE</Button>
                </Col>
            </Row> */}

            {
                isFetch &&

                <Row className="footer2" justify="center"  >
                    <Col
                        xs={{ span: 22, offset: 0 }}
                        sm={{ span: 22, offset: 0 }}
                        md={{ span: 6, offset: 1 }}
                        lg={{ span: 5, offset: 2 }}
                        xl={{ span: 5, offset: 2 }}
                        xxl={{ span: 4, offset: 3 }}
                    >
                        <Row>
                            <h3 className="footer2_title">KONTAKT INFORMACIJE</h3>
                        </Row>

                        <Row className="footer2_row_item">
                            <Col className="footer2_kontakt_info_col">
                                <div className="clock_icon" />
                            </Col>
                            <Col className="footer2_kontakt_info_col2">
                                {
                                    storeInfo.data &&
                                    storeInfo.data.business_hours.map((x, y) =>
                                        <h4 className="footer2_item1" key={y}>
                                            {getBusinessHours(x)}
                                        </h4>
                                    )
                                }

                            </Col>
                        </Row>
                        <Row className="footer2_row_item">
                            <Col className="footer2_kontakt_info_col">
                                <div className="call_icon" />
                            </Col>
                            <Col className="footer2_kontakt_info_col2">
                                {storeInfo.data &&
                                    <h4 className="footer2_item1">
                                        {storeInfo.data.telephone.map((x, y) =>
                                            <div key={y}>
                                                <a href={"tel:" + x.tel_num} className="link_item">{x.owner} | {x.tel_num}</a>
                                                <br />
                                            </div>
                                        )}

                                    </h4>
                                }

                            </Col>
                        </Row>
                        <Row className="footer2_row_item">
                            <Col className="footer2_kontakt_info_col">
                                <div className="letter_icon" />
                            </Col>
                            <Col className="footer2_kontakt_info_col2">
                                {storeInfo.data.emails &&
                                    <h4 className="footer2_item1">
                                        {storeInfo.data.emails.map((x, y) =>
                                            <div key={y}>
                                                <a href={"mailto:" + x.email} className="link_item">{x.owner} | {x.email}</a>
                                                <br />
                                            </div>
                                        )}

                                    </h4>
                                }
                            </Col>
                        </Row>
                        <Row className="footer2_row_item">
                            <Col className="footer2_kontakt_info_col">
                                <div className="location_icon" />
                            </Col>
                            <Col className="footer2_kontakt_info_col2">
                                {storeInfo.data &&
                                    <div>
                                        <a target="_blank" href="https://www.google.com/maps/place/27.+marta+13,+Bijeljina+76300,+Bosnia+and+Herzegovina/@44.7544416,19.2166907,21z/data=!4m5!3m4!1s0x475be8e3887f3c17:0x39f8b6c578272fdf!8m2!3d44.7545326!4d19.2165754?hl=en-US" className="link_item">
                                            <h5 className="footer2_item1">
                                                {storeInfo.data.address.street_name}, broj {storeInfo.data.address.street_number}
                                            </h5>
                                            <h5 className="footer2_item1">
                                                {storeInfo.data.address.zip}, {storeInfo.data.address.city_name}
                                            </h5>
                                            <h5 className="footer2_item1">
                                                {storeInfo.data.address.region_name}
                                            </h5>
                                            <h5 className="footer2_item1">
                                                {storeInfo.data.address.state_name}
                                            </h5>
                                        </a>
                                    </div>
                                }

                            </Col>
                        </Row>

                    </Col>
                    <Col xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 5, offset: 0 }} xxl={{ span: 5, offset: 0 }}>
                        <Row>
                            <h3 className="footer2_title">PREPORUČENE STRANICE</h3>
                        </Row>
                        <Row className="footer2_row_item">
                            <Link className="footer2_item2" to="/" onClick={goToTop}>Početna</Link>
                        </Row>
                        <Row className="footer2_row_item" >
                            <Link className="footer2_item2" to="/objave" onClick={goToTop}>Objave</Link>
                        </Row>
                        {/* <Row className="footer2_row_item">
                            <Link className="footer2_item2" to="/akcije" onClick={goToTop}>Akcije</Link>
                        </Row>
                        <Row className="footer2_row_item">
                            <Link className="footer2_item2" to="/katalog" onClick={goToTop}>Katalog</Link>
                        </Row> */}
                        <Row className="footer2_row_item">
                            <Link className="footer2_item2" to="/partneri" onClick={goToTop}>Partneri</Link>
                        </Row>
                        <Row className="footer2_row_item">
                            <Link className="footer2_item2" to="/kontakt" onClick={goToTop}>Kontakt</Link>
                        </Row>

                    </Col>
                    <Col xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 5, offset: 0 }} xxl={{ span: 5, offset: 0 }}>
                        <Row>
                            <h3 className="footer2_title">NAČINI PLAĆANJA</h3>
                        </Row>
                        <Row >
                            <Col>
                                <div className="visa_icon" />
                            </Col>
                            <Col>
                                <div className="mastercard_icon" />
                            </Col>

                            {/* <Col>
                            <div className="americanexpress_icon" />
                        </Col> */}
                            {/* <Col>
                            <div className="paypal_icon" />
                        </Col> */}
                            <Col>
                                <div className="maestro_icon" />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }} md={{ span: 5, offset: 0 }} lg={{ span: 5, offset: 0 }} xl={{ span: 5, offset: 0 }} xxl={{ span: 5, offset: 0 }}>

                        <Row>
                            <h3 className="footer2_title">SOCIJALNE MREŽE</h3>
                        </Row>
                        <Row >
                            <Col className="footer2_item4"><a target="_blank" href="https://www.facebook.com/infocomp1/" className="link_item"><FacebookFilled /></a></Col>
                            {/* <Col className="footer2_item4"><a href="https://www.facebook.com/infocomp1/" target="_blank" className="link_item"><TwitterOutlined/></a></Col> */}
                            <Col className="footer2_item4"><a target="_blank" href="https://www.instagram.com/infocomp_doo/?hl=en" className="link_item"><InstagramOutlined /></a></Col>
                        </Row>
                    </Col>
                </Row>
            }

            <Row className="footer3" justify="center">
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}  >
                    <center>Copyright <b>INFOCOMP &copy;</b> {new Date().getFullYear()} Sva prava zadržana!</center>
                </Col>
            </Row>


        </Footer>

    );
}


export default AppFooter