import {
    PUT_SLIDER,
    FETCH_SLIDER
} from './constants';

//end users
export const putSlider = payload => ({
    type: PUT_SLIDER,
    payload
});

export const fetchSlider = payload => ({
    type: FETCH_SLIDER,
    payload
})
