import React from "react";
import { Form, Input, Button, Checkbox, Col, Row } from 'antd';
import AppBreadcrumb from "../breadcrumb/AppBreadcrumb";
const Profile = () => {
  return (

    <Row justify="center" >

      <Col
        // className="category_page_list"
        xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
        md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
        xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
      >
        <Row className="breadcrumb_path">
          <AppBreadcrumb />
        </Row>
        <Row>
          <h1 className="page_under_construction">--------- Stranica je u izradi -----------</h1>
        </Row>

      </Col>
    </Row>
  );
}

export default Profile;