
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from "antd";

import './Products.css'
import { ProductCard } from '../my_components'
import { homeProductsSelector } from '../../store/my_selectors'
import { fetchHomeProducts } from "../../store/products/actions";

const RecentlyAddedProducts = () => {
    const dispatch = useDispatch();
    const products = useSelector(homeProductsSelector);
    const isFetchProducts = products.isFetch;

    useEffect(() => {
        if (isFetchProducts === false) {
            dispatch(
                fetchHomeProducts()
            );
        }
    }, []);

    return (
        <Col>
            <Row className="line">
                <h1 className="recently_added_product_title">Nedavno dodati proizvodi</h1>
            </Row>
            <Row className="recently_added_products_copy">
                {
                    isFetchProducts && products.data.map((product) =>
                        <ProductCard
                            product={product}
                            key={product.product_name}
                        />
                    )
                }
            </Row>
        </Col>
    );
};

export default RecentlyAddedProducts;