import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Row, Col, Image, Menu, Slider, InputNumber } from "antd";
import { goToTop } from "../../utils";
const { SubMenu } = Menu;
const FilterMenuProduct = ({ priceSlider, setPriceSlider, applyFilters, onClose }) => {
    const handleFilterClick = () => {
        // e.preventDefault()
    }
    const onChangeSlider = (e) => {
        setPriceSlider(e);
    };
    const onInputPriceLowerChange = (e) => {
        setPriceSlider([e, priceSlider[1]]);
    };
    const onInputPriceHigherChange = (e) => {
        setPriceSlider([priceSlider[0], e]);
    };

    return (
        <div>
            <Menu
                onClick={handleFilterClick}
                mode="inline"
                className="filter_menu"
                defaultOpenKeys={['filter_menu_1']}
            >
                <SubMenu key="filter_menu_1" title="Cijena">
                    {/* https://ant.design/components/slider/ */}
                    <Menu.Item key="filter_menu_price" className="filter_menu_item">

                        <Row className="filter_menu_slider_price" >
                            {/* TODO da mi salje min i max cenu za slider */}
                            <Slider
                                range
                                marks={{ 0: "0 KM", 10000: "10000 KM" }}
                                min={0}
                                max={10000}
                                style={{ width: '80%', left: '7%', lineHeight: '15px' }}
                                defaultValue={priceSlider}
                                value={priceSlider}
                                onChange={onChangeSlider}
                            />
                        </Row>
                        <Row className="filter_menu_slider_price_label">
                            <Col style={{ width: '50%' }}>
                                <label style={{ padding: '2%', width: '20%' }}>Min</label>
                                <InputNumber

                                    min={1}
                                    max={10000}
                                    style={{
                                        margin: "0",
                                        width: '60%'
                                    }}
                                    value={priceSlider[0]}
                                    onChange={onInputPriceLowerChange}
                                />
                            </Col>
                            <Col style={{ width: '50%' }}>
                                <label style={{ padding: '2%', width: '20%' }}>Max</label>
                                <InputNumber

                                    min={1}
                                    max={10000}
                                    style={{
                                        margin: "0",
                                        width: '60%'
                                    }}
                                    value={priceSlider[1]}
                                    onChange={onInputPriceHigherChange}
                                />
                            </Col>


                        </Row>


                    </Menu.Item>

                </SubMenu>
            </Menu>
            <Row>
                <Button style={{ width: '100%' }}
                    onClick={(e) => {
                        applyFilters()
                        onClose()
                    }}
                >Primjeni</Button>
            </Row>
        </div>
    );
}

export default FilterMenuProduct