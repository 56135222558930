import { combineReducers } from 'redux';


import cartReducer from './cart/reducer';
import storeInfoReducer from './storeInfo/reducer';
import categoriesReducer from './categories/reducer';
import productsReducer from './products/reducer';
import sliderReducer from './slider/reducer';
import postReducer from './post/reducer';
import imageReducer from './image/reducer';
import auxilliaryVariablesReducer from './auxilliaryVariables/reducer';


const rootReducer = combineReducers({
    cartReducer,
    storeInfoReducer,
    categoriesReducer,
    productsReducer,
    sliderReducer,
    postReducer,
    imageReducer,
    auxilliaryVariablesReducer
});


export default (state, action) => {
    return rootReducer(state, action);
};