import axiosCustom from "./HttpBaseClient";

class ProductsService {

    // http://api.infocomp.ba/api/product?page=1&size=50&pt_name=1
    // http://api.infocomp.ba/api/product?page=1&size=50
    // http://api.infocomp.ba/api/product
    fetchProducts = async (payload) => {
        try {
            const { data, status } = await axiosCustom.get('/product', payload)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    throw error;
                });
            return { data, status };
        } catch (error) {
            throw error;
        }
    };

    fetchProductDetailByName = async (payload)=> {
        try {
            const { data, status } = await axiosCustom.get(`/product/`+ payload)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    throw error;
                });
            return { data, status };
        } catch (error) {
            throw error;
        }
    };


};

export default new ProductsService();