// FUNKCIJA ZA SKRAĆIVANJE TEKSTA U ODNOSU NA NEKU DUŽINU. NA KRAJU DODAJE ...
export const truncateString = (str, length) => {
  const ending = "...";

  if (length == null) {
    length = 100;
  }

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};
// FUNKCIJA ZA POVRATAK NA POČETAK STRANICE
export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
  // window.location.reload
};
/* FUNKCIJA ZA ODLAZAK NA PROSLEĐENU POZICIJU NA EKRANU
  PRIMER:
  const scrollTop = document.getElementById("text").scrollHeight
  goToExample(scrollTop)
*/
export const goToExample = (topHeight) => {
  window.scrollTo({
    top: topHeight,
    behavior: 'smooth',
  });
};
// FUNKCIJA KOJA PARSIRA RADNO VREME I PRIKAZUJE U FORMATU 00:00-00:00
export const getBusinessHours = (x) => {
  let businessHours = x.wd_name + " / "
  if (x.working_status == 0) {
    businessHours += "Ne radimo"
  } else {
    businessHours += x.open_hour.split(":")[0] + ":" + x.open_hour.split(":")[1] + "-" + x.close_hour.split(":")[0] + ":" + x.close_hour.split(":")[1]
  }
  return businessHours
}
/*  FUNKCIJA KOJA MOŽE DA ZABRANI SKROLOVANJE PO EKRANU
 const lockScroll = React.useCallback(() => {
   document.body.style.overflow = 'hidden';
 }, [])
*/
/*  FUNKCIJA KOJA MOŽE DA DOZVOLI SKROLOVANJE PO EKRANU
 const unlockScroll = React.useCallback(() => {
  document.body.style.overflow = '';
 }, [])
 */

// FUNKCIJA KOJA DODAJE SEO NA SVAKU STRANICU
// stavlja predefinisane vrednosti ako nema nista
const baseTitle = "Infocomp";
const baseDescription = "Velika ponuda od preko 10.000 artikala - desktop računari, laptop računari, telefoni, TV i kućni aparati. Mogućnost kupovine na rate.";
const baseKeywords = ["desktop računari", "laptop računari", "telefoni", "TV", "kućni aparati"];
const baseUrl = "https://infocomp.ba/";
const baseImage = "%PUBLIC_URL%/whole-logo-512-512.png";

export const setSEO = ({ title = baseTitle, description = baseDescription, keywords = baseKeywords, url = baseUrl, image = baseImage, ...props }) => {
  title = (title !== null && title !== "" ? title : baseTitle) + " | Infocomp";
  description = description !== null && description !== "" ? description : baseDescription;
  keywords = keywords !== null && keywords !== [] ? keywords.join() : baseKeywords.join();
  url = url !== null && url !== "" ? url : baseUrl;
  image = image !== null && image !== "" ? image : baseImage;

  document.title = title;
  document.querySelector('meta[name="title"]').content = title;
  document.querySelector('meta[name="description"]').content = description;
  document.querySelector('meta[name="keywords"]').content = keywords;
  // Meta Reac Helmet
  document.querySelector('meta[data-react-helmet="true"][property="og:site_name"]').content = url;
  document.querySelector('meta[data-react-helmet="true"][itemprop="name"]').content = title;
  document.querySelector('meta[data-react-helmet="true"][itemprop="description"]').content = description;
  // Open Graph / Facebook
  document.querySelector('meta[property="og:title"]').content = title;
  document.querySelector('meta[property="og:description"]').content = description;
  document.querySelector('meta[property="og:url"]').content = url;
  document.querySelector('meta[property="og:image"]').content = image;
  // Twitter
  document.querySelector('meta[property="twitter:title"]').content = title;
  document.querySelector('meta[property="twitter:description"]').content = description;
  document.querySelector('meta[property="twitter:image"]').content = image;
  document.querySelector('meta[property="twitter:url"]').content = url;

}