import {
    PUT_WEBP_SUPPORT,
} from '../constants';

import * as computationFunctions from './computation-functions';

//ovdje ce ici importi za put akcije

//inicijalne stanje stora
const initialState = {
    webpSupport: null,
};

const imageReducer = (state = initialState, { type, payload }) => {
    if (actionHandler.hasOwnProperty(type)) {
        return actionHandler[type](state, payload);
    }
    return state;
};

//ovdje se ubacuju computation funckije
const actionHandler = {
    [PUT_WEBP_SUPPORT]: computationFunctions.putWebpSupport,
};

export default imageReducer;