import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useLocation } from "react-router-dom";
import { Row, Col, Breadcrumb } from "antd";

import './Post.css';
import { PhotoDisplayHandler } from '../my_components'
import { individualPostSelector, deviceSelector } from '../../store/my_selectors'
import { fetchIndividualPost } from "../../store/post/actions"


const PostAndPartnerCardDetail = ({ }) => {
    const location = useLocation();
    const current = location.pathname.split("/")[1];
    const params = useParams();
    const dispatch = useDispatch();
    const individualPost = useSelector(individualPostSelector);
    const isFetch = individualPost.isFetch;
    const deviceData = useSelector(deviceSelector);
    const device = deviceData.device;
    const orientation = deviceData.orientation;

    useEffect(() => {

        dispatch(
            fetchIndividualPost(params.slug)
        );

    }, [params, current]);


    return (
        <Row justify="center" >

            <Col
                xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
                md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
                xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
            >
                {
                    isFetch === true &&
                    <>
                        <Row className="breadcrumb_path">
                            {/* TODO - iskoristiti app breadcrumb */}
                            {/* <AppBreadcrumb /> */}
                            <Breadcrumb>
                                <Breadcrumb.Item><Link to="/">Početna</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to={`/${current}`}>{current === 'objave' ? 'Objave' : 'Partneri'}</Link></Breadcrumb.Item>
                                <Breadcrumb.Item><Link to={`/${current}/${params.slug}`}>{individualPost.data.post_title}</Link></Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                        <Row className="post_components">
                            <Col>
                                <h2 className="post_card_date_detail">{individualPost.data.created_at}</h2>
                            </Col>
                            <Col>
                                <h1 className="post_card_title">{individualPost.data.post_title}</h1>
                            </Col>
                            <Col>
                                <h3 className="post_card_subtitle">{individualPost.data.post_description}</h3>
                            </Col>
                            {
                                individualPost.data.images[0] && individualPost.data.images[1] &&
                                <Col className="post_card_picture_detail_col">
                                    <PhotoDisplayHandler
                                        img_name={device == 'mobile' ? individualPost.data.images[1].img_name : individualPost.data.images[0].img_name}
                                        dir="posts"
                                        calculate={false}
                                        img_class_name="post_card_picture_detail"
                                        img_alt={individualPost.data.post_title}
                                    // height={device =='mobile' ? '450px' : '250px'}
                                    />

                                </Col>
                            }
                            <Col className="post_html_div">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: individualPost.data.post_content ? individualPost.data.post_content : ""
                                    }}
                                />
                            </Col>

                        </Row>
                    </>
                }


            </Col>
        </Row>

    );
}

export default PostAndPartnerCardDetail;