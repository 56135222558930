import React, { useState } from "react";
import { Link } from "react-router-dom"
import { Space } from "antd";
import { MenuOutlined } from '@ant-design/icons';
import './CategoryMenu.css'
import MenuItem from "./MenuItem";

const CategoryMenu = ({ categories, isFetchCategories }) => {

    const [visibilityDropMenu, setVisibilityDropMenu] = useState(true)


    const showDropdown = (e) => {
        setVisibilityDropMenu(false)
    }
    const hideDropdown = (e) => {
        setVisibilityDropMenu(true)
    }

    return (
        <div >
            <nav className="product_menu_nav"
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}  
            >
                <div className="product_menu_wrapper" >
                    <ul className="product_menu_drop_list">
                        <div>
                        
                            <Link to="/proizvodi" >
                                <Space className="product_menu_space">
                                    <MenuOutlined className="product_menu_space_icon" />
                                    <p className="product_menu_space_title">PROIZVODI</p>
                                </Space>
                            </Link>


                            <div className="product_menu_drop_menu" hidden={visibilityDropMenu}>

                                {
                                    isFetchCategories && categories && categories.map((category, category_key) =>
                                        <MenuItem
                                            categories={categories}
                                            category={category}
                                            key={category_key}
                                            hideDropdown={hideDropdown}
                                        />
                                    )

                                }
                            </div>
                        </div>
                    </ul>

                </div>
            </nav>
        </div>
    );
}

export default CategoryMenu