import React from "react";
import { Form, Input, Button, Checkbox, Col } from 'antd';
import { useHistory, Link } from "react-router-dom";

import '../../styles/form/form.css';

const Registration = () => {

  const history = useHistory();

  const onFinish = (values) => {
    //TODO dodati funkciju prijave
  };

  const onFinishFailed = (errorInfo) => {
    //TODO dodati ogranicenja ili neko obavestenje
  };
  return (
    <Col 
    className="app_col_center" 
    xs={{ span: 20, offset: 1 }} sm={{ span: 20, offset: 2 }}  
    md={{ span: 17, offset: 3 }} lg={{ span: 17, offset: 3 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}
    >
      <Form
        name="basic"
        initialValues={{
          remember: false,
        }}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 22,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="login_form"
        layout="vertical"
      >
        <h1 className="login_form_title">Registruj se</h1>
        
        <Form.Item
          label="Ime"
          name="ime"
          className="login_form_item"
          rules={[
            {
              required: true,
              type: "text",
              message: 'Unesite svoje ime!',
            },
          ]}
        >
          <Input className="login_form_item_input"/>
        </Form.Item>

        <Form.Item
          label="Prezime"
          name="prezime"
          className="login_form_item"
          rules={[
            {
              required: true,
              type: "text",
              message: 'Unesite svoje prezime!',
            },
          ]}
        >
          <Input className="login_form_item_input"/>
        </Form.Item>

        <Form.Item
          label="Email adresa"
          name="email_address"
          className="login_form_item"
          rules={[
            {
              required: true,
              type: "email",
              message: 'Unesite svoju email adresu!',
            },
          ]}
        >
          <Input className="login_form_item_input"/>
        </Form.Item>

        <Form.Item
          label="Lozinka"
          name="password"
          className="login_form_item"
          rules={[
            {
              required: true,
              message: 'Unesite svoju lozinku!',
            },
          ]}
        >
          <Input.Password className="login_form_item_input"/>
        </Form.Item>

        <Form.Item
          label="Potvrdite lozinku"
          name="password"
          className="login_form_item"
          rules={[
            {
              required: true,
              message: 'Morate potvrditi lozinku!',
            },
          ]}
        >
          <Input.Password className="login_form_item_input"/>
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          className="login_form_item_checkbox"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Checkbox 
            className="login_form_item_checkbox_input"  
          >Zapamti podatke za prijavu</Checkbox>
        </Form.Item>

        <Form.Item
          name="bilten"
          valuePropName="checked"
          className="login_form_item_checkbox"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Checkbox 
            className="login_form_item_checkbox_input"  
          >Prijavi se na bilten</Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 0,
            span: 22,
          }}
          className="login_form_item"
        >
          <Button type="primary" htmlType="submit" className="login_form_item_button">
            REGISTRUJ SE
          </Button>
        </Form.Item>
        <h2 className="login_form_is_new_text">Imate nalog? <Link to="/prijava">Prijavi se</Link></h2>
       
      </Form>

    </Col>
  );
}

export default Registration;
