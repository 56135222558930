import {
    PUT_CATEGORIES,
    FETCH_CATEGORIES
} from './constants';


export const fetchCategories = payload => ({
    type: FETCH_CATEGORIES,
    payload
})

export const putCategories = payload => ({
    type: PUT_CATEGORIES,
    payload
})

