import React from "react"
import { Row, Col, Menu, Button, Image } from "antd";
import { Link, useHistory } from "react-router-dom"
import { goToTop, truncateString } from "../../utils";
import { API_IMAGES_URL, API_CATEGORIES_IMAGE_URL } from "../../config";
import './CategoryPage.css'
import PhotoDisplayHandler from "../general/PhotoDisplayHandler";

const CategoryPageItem = ({ category }) => {
    const history = useHistory();

    return (
        <Row className="category_page_item" onClick={() => {
            goToTop()
            history.push(`/proizvodi/${category.slug}`)
        }}>
            <Col className="category_page_item_icon">
                <PhotoDisplayHandler
                    img_name={category.img_name}
                    dir="categories"
                    calculate={false}
                    img_class_name="product_type_page_item_box_image"
                    img_alt={category.pc_name}
                />
            </Col>
            <Col className="category_page_item_name">
                <div>{truncateString(category.pc_name, 50)}</div>
            </Col>
        </Row>

    );
}

export default CategoryPageItem;