import React from "react";
import { Menu } from "antd";

import './FilterMenu.css'
const { SubMenu } = Menu;

// ne koristi se za sada 
const FilterMenu = (menuList) => {

    const handleClick = (e) => {

    };

    return (
        // TODO doraditi u zavisnosti od toga sta je trazeno
        <Menu
            onClick={handleClick}
            // style={{ width: 256 }}
            // defaultSelectedKeys={['1']}
            // defaultOpenKeys={['sub1']}
            mode="inline"
            className="filter_menu"
        >
            <SubMenu key="filter_menu_1" title="Cijena">
                <Menu.Item key="filter_menu_11_1">Option 1</Menu.Item>
                <Menu.Item key="filter_menu_11_2">Option 2</Menu.Item>
            </SubMenu>
            {/* <SubMenu key="filter_menu_2" title="Proizvodjac">
                <Menu.ItemGroup key="filter_menu_21" title="Item 1">
                    <Menu.Item key="filter_menu_21_1">Option 1</Menu.Item>
                    <Menu.Item key="filter_menu_21_2">Option 2</Menu.Item>
                </Menu.ItemGroup>
                <Menu.ItemGroup key="filter_menu_22" title="Proizvodjac">
                    <Menu.Item key="filter_menu_21_3">Option 3</Menu.Item>
                    <Menu.Item key="filter_menu_21_4">Option 4</Menu.Item>
                </Menu.ItemGroup>
            </SubMenu>
            <SubMenu key="filter_menu_3" title="Tip">
                <Menu.Item key="filter_menu_31">Option 5</Menu.Item>
                <Menu.Item key="filter_menu_32">Option 6</Menu.Item>
                <SubMenu key="filter_menu_33" title="Submenu">
                    <Menu.Item key="filter_menu_33_1">Option 7</Menu.Item>
                    <Menu.Item key="filter_menu_33_2">Option 8</Menu.Item>
                </SubMenu>
            </SubMenu>
            <SubMenu key="filter_menu_4" title="Navigation Three">
                <Menu.Item key="filter_menu_41">Option 9</Menu.Item>
                <Menu.Item key="filter_menu_42">Option 10</Menu.Item>
                <Menu.Item key="filter_menu_43">Option 11</Menu.Item>
                <Menu.Item key="filter_menu_44">Option 12</Menu.Item>
            </SubMenu> */}
        </Menu>
    );
};

export default FilterMenu;