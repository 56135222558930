import React from "react"
import { Row, Col } from "antd";
import { Link, useHistory } from "react-router-dom"
import { goToTop, truncateString } from "../../utils";
import './CategoryPage.css'
import PhotoDisplayHandler from "../general/PhotoDisplayHandler";

const SubCategoryPageItem = ({ subcategory, category }) => {

    const history = useHistory();
    return (
        <Row className="category_page_item" onClick={() => {
            goToTop()
            if (subcategory.children.length == 0)
                history.push(`/proizvodi/${category}/${subcategory.slug}/pregled`)
            else
                history.push(`/proizvodi/${category}/${subcategory.slug}`)
        }}>
            <Col className="category_page_item_icon">
                <PhotoDisplayHandler
                    img_name={subcategory.img_name}
                    dir="categories"
                    calculate={false}
                    img_class_name="product_type_page_item_box_image"
                    img_alt={subcategory.pc_name}
                />
            </Col>
            <Col className="category_page_item_name">
                <div>{truncateString(subcategory.pc_name, 50)}</div>
            </Col>
        </Row>

    );
}

export default SubCategoryPageItem;