import { take, put, call } from 'redux-saga/effects'

import { FETCH_HOME_PRODUCTS, FETCH_PAG_PRODUCTS, FETCH_PAG_PRODUCTS_BY_PT_NAME, FETCH_PRODUCT_DETAIL } from './constants'
import { putHomeProducts, putPagProducts, putPagProductsByPtName, putProductDetail} from './actions'
import ProductsService from '../../services/ProductsService'


export function* fetchPagProductsByPtIdFunction() {
    const { payload } = yield take(FETCH_PAG_PRODUCTS_BY_PT_NAME)
    yield put(putPagProductsByPtName({'isFetch': false}))
    const data = yield call(ProductsService.fetchProducts, payload)
    yield put(putPagProductsByPtName({
        'data': data.data,
        'isFetch': true
    }))
}
export function* fetchPagProductsFunction() {
    const { payload } = yield take(FETCH_PAG_PRODUCTS)

    yield put(putPagProducts({'isFetch': false}))
    const data = yield call(ProductsService.fetchProducts, payload)
    yield put(putPagProducts({
        'data': data.data,
        'isFetch': true
    }))
}

export function* fetchHomeProductsFunction() {
    const { payload } = yield take(FETCH_HOME_PRODUCTS)
    yield put(putHomeProducts({'isFetch': false}))
    const data = yield call(ProductsService.fetchProducts, payload)
    yield put(putHomeProducts({
        'data': data.data.data,
        'isFetch': true
    }))
}

export function* fetchProductDetailByName(){
    const { payload } = yield take(FETCH_PRODUCT_DETAIL)
    yield put(putProductDetail({'isFetch': false}))
    const data = yield call(ProductsService.fetchProductDetailByName, payload)
    yield put(putProductDetail({
        'data': data.data.data,
        'isFetch': true
    }))
}

