import React from "react"
import { useParams } from "react-router-dom";
import { Col, Row } from "antd"
import { useSelector } from 'react-redux';

import './CategoryPage.css'
import { SlideshowAd, ProductsBreadcrumb,  ProductTypePageItem, RecentlyAddedProducts } from '../my_components'
import { categoriesSelector } from '../../store/my_selectors'

const ProductTypePage = () => {
    const params = useParams();
    const categories = useSelector(categoriesSelector);

    return (

        <Col>
            <Row>
                {/* REKLAMA */}
                <SlideshowAd />

            </Row>
            <Row justify="center" >
                <Col
                    className="category_page_list"
                    xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
                    md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
                    xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
                >
                    <Row className="category_page_breadcrumb" >
                        <ProductsBreadcrumb parameters={Object.values(params)}/>
                    </Row>
                    <Row>
                        <h1 className="category_page_title">Izaberi kategoriju</h1>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        {
                            categories.isFetch
                            && categories.data.filter(x => x.slug == params.category)[0].children.filter(y => y.slug == params.subcategory)[0]
                            && categories.data.filter(x => x.slug == params.category)[0].children.filter(y => y.slug == params.subcategory)[0].children.map((product_type, product_type_key) =>
                                <ProductTypePageItem
                                    category={params.category}
                                    subcategory={params.subcategory}
                                    product_type={product_type}
                                    key={product_type_key}
                                />
                            )

                        }
                    </Row>
                    <RecentlyAddedProducts />

                </Col>
            </Row>
        </Col>
    );
}

export default ProductTypePage;