import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";

const ProductsBreadcrumb = ({parameters = [], isDetailsView = false}) => {
    const paramsList = parameters;
    const path_products = "/proizvodi";
    const path_product = "/proizvod";

    const ifParamList1 = (result) =>{
        var path1 = path_products + "/"+ paramsList[0];
        result = result.concat(<Breadcrumb.Item key={3} > <Link to={path1}>{paramsList[0]}</Link></Breadcrumb.Item >)
        return result
    }  
    const ifParamList2 = (result) =>{
        result = ifParamList1(result);
        var path2 = path_products + "/" + paramsList[0] + "/" + paramsList[1];
        result = result.concat(<Breadcrumb.Item key={paramsList[1]} > <Link to={path2}>{paramsList[1]}</Link></Breadcrumb.Item >)
        return result
    }
    const ifParamList3 = (result, path) =>{
        result = ifParamList2(result);
        var path3 = path + "/" + paramsList[0]+ "/" + paramsList[1] + "/" + paramsList[2];
        result = result.concat(<Breadcrumb.Item key={paramsList[2]} > <Link to={path3}>{paramsList[2]}</Link></Breadcrumb.Item >)
        return result
    }

    const returnAllPath = () => {
        let result = [
            <Breadcrumb.Item key="pocetna" ><Link to="/">Početna</Link> </Breadcrumb.Item>,
            <Breadcrumb.Item key="kategorije" ><Link to="/proizvodi">Kategorije</Link> </Breadcrumb.Item>
        ]
        if(isDetailsView){
            if(paramsList.length == 4){
                result = ifParamList3(result, path_products)
                var path4 = path_product + "/"+ paramsList[3];
                result = result.concat(<Breadcrumb.Item key={paramsList[3]} > <Link to={path4}>{paramsList[3]}</Link></Breadcrumb.Item >)
            }else if(paramsList.length == 3){
                result = ifParamList2(result, path_products)
                var path3 = path_product + "/"+ paramsList[2];
                result = result.concat(<Breadcrumb.Item key={paramsList[2]} > <Link to={path3}>{paramsList[2]}</Link></Breadcrumb.Item >)
            }
        }else{
            if(paramsList.length == 3){
                result = ifParamList3(result, path_products)
            }else if(paramsList.length == 2){
                result = ifParamList2(result)
            }else if(paramsList.length == 1){
                result = ifParamList1(result)
            }
        }
        
        
        return result
    }

    return (
        <Breadcrumb>
            {returnAllPath()}
        </Breadcrumb>
    );
};

export default ProductsBreadcrumb;