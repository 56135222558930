export function putPagProductsPtName(state, payload){
    return {
        ...state, 
        pag_products_pt_name: payload
    }
}

export function putPagProducts(state, payload){
    return {
        ...state, 
        pag_products: payload
    }
}

export function putHomeProducts(state, payload){
    return {
        ...state, 
        home_products: payload
    }
}

export function putProductDetail(state, payload){
    return {
        ...state,
        product_detail: payload
    }
}