import {
    PUT_STORE_INFO
} from '../constants';

import * as computationFunctions from './computation-functions';

const initialState = {
    store_info: {
        data: [],
        isFetch: false
    },
};

const storeInfoReducer = (state = initialState, { type, payload }) => {
    if (actionHandler.hasOwnProperty(type)) {
        return actionHandler[type](state, payload);
    }
    return state;
};

const actionHandler = {
    [PUT_STORE_INFO]: computationFunctions.putStoreInfo,
};

export default storeInfoReducer;