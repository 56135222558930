import axiosCustom from "./HttpBaseClient";

// TODO : POVEZATI SA GENERICKOM FUNKCIJOM I SA PRAVOM PUTANJOM
class PostService {
    // https://api.infocomp.ba/api/post?page=1&size=50&pot=Vijest&&lang_id=sr_Latn
    // https://api.infocomp.ba/api/post?page=1&size=50
    // https://api.infocomp.ba/api/post
    fetchPost = async (payload) => {
        try {
            const { data, status } = await axiosCustom.get('/post', payload)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    throw error;
                    // return error.response;
                });
            return { data, status };
        } catch (error) {
            throw error;
        }
    };
    // https://api.infocomp.ba/api/post/1
    fetchIndividualPost = async (payload) => {
        try {
            const { data, status } = await axiosCustom.get("/post/" + payload)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    throw error;
                    // return error.response;
                });
            return { data, status };
        } catch (error) {
            throw error;
        }
    };

};

export default new PostService();