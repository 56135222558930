import React, { useEffect } from "react"

import { Row, Col, Image, Rate, Button, Space, Input } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import { truncateString, setSEO } from "../../utils";
import './ProductDetails.css'
import '../header/AppHeader.css'
import { RecentlyAddedProducts, PhotoDisplayHandler, ProductsBreadcrumb } from '../my_components'

import { API_PRODUCTS_IMAGE_URL } from "../../config";


const ProductDetailsComponent = ({ isFetchProduct, productDetail, returnAllPath, coverImagePath, setCoverImagePath, setInformationBarProm, informationBarProm }) => {

    useEffect(()=> {
        if(productDetail !== null){
            
            setSEO({
                title: productDetail.data.product_name !== null ? productDetail.data.product_name : "", 
                description: productDetail.data.product_description !== null ? productDetail.data.product_description : "", 
                keywords: productDetail.data.seo !== null ? productDetail.data.seo : [],
                url: window.location,
                image: productDetail.data.bg_img_name !== null ? API_PRODUCTS_IMAGE_URL + productDetail.data.bg_img_name : ""
            });
        }
    }, [productDetail])


    return (
        <Row justify="center" >
            {isFetchProduct && productDetail !== null &&
                <Col
                    xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
                    md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
                    xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
                >
                    <Row className="breadcrumb_path">
                        <ProductsBreadcrumb
                            parameters={returnAllPath()}
                            isDetailsView={true}
                        />
                    </Row>

                    <Row className="product_detail_row">
                        <Col className="product_detail_images">
                            <Row className="product_detail_cover_image_row" >
                                {/* <PhotoDisplayHandler
                                    dir="products"
                                    img_name={(coverImagePath === "" ? productDetail.data.bg_img_name : coverImagePath)}
                                    key={productDetail.data.bg_img_name}
                                    img_class_name="product_detail_cover_image"
                                    img_alt={(coverImagePath === "" ? productDetail.data.bg_img_name : coverImagePath)}

                                /> */}
                                <Image
                                    src={API_PRODUCTS_IMAGE_URL + (coverImagePath === "" ? productDetail.data.bg_img_name : coverImagePath)}
                                    key={productDetail.data.bg_img_name}
                                    className="product_detail_cover_image"
                                />
                            </Row>
                            <Row className="product_detail_other_images">
                                <div
                                    img_class_name="product_detail_other_image"
                                    onClick={() => {
                                        setCoverImagePath(productDetail.data.bg_img_name)
                                    }}
                                >
                                    <PhotoDisplayHandler
                                        img_name={productDetail.data.bg_img_name}
                                        dir="products"
                                        calculate={true}
                                        img_class_name="product_detail_other_image_item"
                                        img_alt={productDetail.data.product_name}
                                    // width='100%'

                                    />
                                </div>

                                {/* <Image
                                    src={API_PRODUCTS_IMAGE_URL + productDetail.data.bg_img_name}
                                    className="product_detail_other_image"
                                    preview={false}


                                /> */}
                                {productDetail.data.images && productDetail.data.images.map((s, index) => {

                                    return <div
                                        key={index}
                                        onClick={() => {
                                            setCoverImagePath(s)
                                        }}
                                    >
                                        <PhotoDisplayHandler
                                            img_name={s}
                                            dir="products"
                                            calculate={true}
                                            img_class_name="product_detail_other_image_item"
                                            img_alt={productDetail.data.product_name}
                                        // width='100%'

                                        />
                                        {/* <Image
                                        src={API_PRODUCTS_IMAGE_URL + s.img_name}
                                        key={index}
                                        className="product_detail_other_image"
                                        preview={false}
                                        
                                        /> */}

                                    </div>

                                })}

                            </Row>


                        </Col>
                        <Col className="product_details">
                            <Row>
                                <h1 className="product_details_title">{productDetail.data.product_name}</h1>
                            </Row>
                            <Row>
                                <h2 className="product_details_subtitle"><a target="_blank" href={productDetail.data.vendor_url}>{productDetail.data.vendor_name}</a></h2>
                            </Row>
                            {/* ZA SADA NE TREBA */}
                            {/* <Row>
                                <Col className="product_details_rate_col">
                                    <Rate allowHalf defaultValue={5} className="product_details_rate" />
                                </Col>

                                <Button className="give_comment" onClick={giveComment}>
                                    <Space type="vertical">
                                        <div className="ostavite_komentar_icon" />
                                        <p className="product_details_text">Ostavite recenziju</p>
                                    </Space>
                                </Button>
                            </Row> */}
                            {/* <Row className="product_details_icons">
                                <Space type="vertical">
                                    <CheckOutlined className="product_details_icon" />
                                    <Divider />
                                    <p className="product_details_text">Na stanju</p>
                                </Space>
                                <Space type="vertical">
                                    <ClockCircleFilled className="product_details_icon" />
                                    <Divider />
                                    <p className="product_details_text">Rok isporuke 3 dana</p>
                                </Space>
                                <Space type="vertical">
                                    <div className="kamion_icon" />
                                    <Divider />
                                    <p className="product_details_text">Besplatna isporuka</p>
                                </Space>
                            </Row> */}
                            {/* Ograniciti tekst */}
                            <Row className="product_details_description">
                                {productDetail.data.product_description ? truncateString(productDetail.data.product_description, 300) : "Bez opisa trenutno."}
                            </Row>
                            {/* <Row className="product_details_old_price">
                                {proizvod.valuta} {proizvod.stara_cena}
                            </Row> */}
                            <Row className="product_details_price">
                                {productDetail.data.price} KM
                            </Row>
                            {/* <Row className="product_details_saving">
                                Ušteda: {proizvod.valuta} {(proizvod.stara_cena - proizvod.cena).toFixed(2)}
                            </Row> */}
                            {/* <Row className="product_details_saving">
                                Šifra artikla: {productDetail.data.pli_id}
                            </Row> */}

                            {/* <Row className="product_details_cart">
                                <Button className="product_detail_cart_operator" onClick={() => {
                                    setQuantityOfProduct(quantityOfProduct => {
                                        if (quantityOfProduct !== 0) {
                                            return quantityOfProduct - 1
                                        } else {
                                            return 0
                                        }
                                    })
                                }}>-</Button>
                                <p className="product_detail_cart_quantity" >{quantityOfProduct}</p>
                                <Button className="product_detail_cart_operator" onClick={() => { setQuantityOfProduct(quantityOfProduct => quantityOfProduct + 1) }}>+</Button>

                                <Button 
                                className="add_to_chart_button "
                                onClick={addToChart}
                                >DODAJ U KORPU</Button>
                            </Row> */}
                        </Col>
                    </Row>

                    <Row className="product_detail_information_bar">
                        <Button className="product_detail_information_bar_add"
                            onClick={() => setInformationBarProm(1)}
                        >
                            <Space type="vertical">
                                <InfoCircleOutlined className="light-icons" />
                                <h2 className="product_detail_information_bar_text">DODATNE INFORMACIJE</h2>
                            </Space>
                        </Button>
                        <Button className="product_detail_information_bar_review"
                        // onClick={() => setInformationBarProm(2)}
                        >
                            <Space type="vertical">
                                {/* ZA SADA NE TREBA */}
                                {/* <FormOutlined className="light-icons" /> */}
                                {/* <h2 className="product_detail_information_bar_text">RECENZIJE</h2> */}
                            </Space>
                        </Button>
                    </Row>
                    {
                        informationBarProm === 1 &&
                        <Row className="product_detail_information">
                            <Col className="product_detail_information_table">
                                <Row>
                                    <h3 className="product_details_subtitle">Specifikacije i detalji</h3>
                                </Row>
                                {
                                    productDetail.data.specification.map((spec, kljuc) => {
                                        if ((kljuc % 2) === 0) {
                                            return (
                                                <Row className="product_detail_information_table_even" key={kljuc}>
                                                    <h3 className="product_detail_information_table_name">{spec.name}: </h3>
                                                    <h3 className="product_detail_information_table_discription">{spec.value}</h3>
                                                </Row>
                                            )
                                        } else {
                                            return (
                                                <Row className="product_detail_information_table_odd" key={kljuc}>
                                                    <h3 className="product_detail_information_table_name">{spec.name}: </h3>
                                                    <h3 className="product_detail_information_table_discription">{spec.value}</h3>
                                                </Row>
                                            )
                                        }
                                    }
                                    )
                                }
                            </Col>

                        </Row>

                    }

                    {/* // TODO : IZDVOJITI KAO POSEBNU KOMPONENTU */}
                    {/* za sada ne treba da bude implementirano */}
                    <Row id="give_comment_row" className="product_detail_information">
                        {
                            informationBarProm === 2 &&
                            <Col className="product_detail_review">
                                <Row>
                                    <h3 className="product_title">Napisi recenziju</h3>
                                </Row>

                                <Row className="product_detail_review_box">
                                    <Col>
                                        <Row>
                                            <h3 className="product_detail_review_name">Vaša ocena:</h3>
                                            <Rate allowHalf defaultValue={2.5} className="product_detail_review_rate" />
                                        </Row>
                                        <Row>
                                            <h3 className="product_detail_review_name">Naziv vaše recenzije:</h3>
                                        </Row>
                                        <Row>
                                            <Input placeholder="Unesite naziv recenzije" className="product_detail_review_input" />
                                        </Row>
                                        <Row>
                                            <h3 className="product_detail_review_name">Vaša recenzija:</h3>

                                        </Row>
                                        <Row>
                                            <Input.TextArea rows={4} placeholder="Unesite tekst..." maxLength={1000} className="product_detail_review_text_area" />
                                        </Row>
                                        <Row className="product_detail_review_submit_button">
                                            <Button className="product_detail_review_submit">POSALJI</Button>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* TODO DODATI PREGLED RECENZIJA */}
                                {/* <Row>
                            Sve recenzije
                        </Row> */}
                            </Col>
                        }
                    </Row>
                    {/* POSLEDNJE DODATI PROIZVODI */}
                    <RecentlyAddedProducts />
                </Col >
            }


        </Row>

    );
};

export default ProductDetailsComponent;