import {
    PUT_CATEGORIES
} from '../constants'

import * as computationFunctions from './computation-functions'

const initialState = {
    categories: {
        data: [],
        isFetch: false
    }
}

const categoriesReducer = (state = initialState, {type, payload}) => {
    if (actionHandler.hasOwnProperty(type)){
        return actionHandler[type](state, payload)
    }
    return state
}

const actionHandler = {
    [PUT_CATEGORIES]: computationFunctions.putCategories,
}

export default categoriesReducer;