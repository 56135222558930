import { take, put, call } from 'redux-saga/effects';

import { FETCH_INDIVIDUAL_POST, FETCH_POST } from './constants';
import { putPost, putIndividualPost } from './actions'
import PostService from '../../services/PostService';


export function* fetchPostFunction() {
    const { payload } = yield take(FETCH_POST);
    yield put(putPost({'isFetch': false}))
    const data = yield call(PostService.fetchPost, payload)
    yield put(putPost({
        'data': data.data,
        'isFetch': true
    }))
}

export function* fetchIndividualPostFunction() {
    const { payload } = yield take(FETCH_INDIVIDUAL_POST);

    yield put(putIndividualPost({ 'isFetch': false }));
    const data = yield call(PostService.fetchIndividualPost, payload);
    yield put(putIndividualPost({
        'data': data.data,
        'isFetch': true
    }));
}