import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Col, Row, Menu, Pagination, Dropdown, Button,  Drawer } from "antd";
import { DownOutlined,  ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import './ProductsView.css'
import { RecentlyAddedProducts, ProductsBreadcrumb, ProductCard, FilterMenuProduct } from '../my_components'
import { pagProductsPtNameSelector, deviceSelector } from '../../store/my_selectors'
import { fetchPagProductsByPtName } from "../../store/products/actions";
import { goToTop } from "../../utils";




const ProductsView = () => {
    const deviceData = useSelector(deviceSelector);
    const device = deviceData.device;
    const orientation = deviceData.orientation;

    const params = useParams();
    const paramsList = Object.values(params);

    const dispatch = useDispatch();
    const products = useSelector(pagProductsPtNameSelector);
    const isFetchProducts = products.isFetch;

    const [pageSize, setpageSize] = useState(12)
    const [current, setCurrent] = useState(1)
    const [nameSort, setNameSort] = useState("Izaberi")
    const [typeSort, setTypeSort] = useState("none")
    const [priceSlider, setPriceSlider] = useState([0, 10000]);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        dispatch(
            fetchPagProductsByPtName({
                params: {
                    page: current,
                    size: pageSize,
                    pt_slug: getProductName()
                }
            })
        );
    }, [current, pageSize, params]);

    const onShowSizeChange = (page, ps) => {
        setCurrent(page)
        setpageSize(ps)
    }

    const handleMenuClick = (e) => {
        // message.info('Click on menu item.');
        setTypeSort(e.key)
        if (e.key != "none" && e.key != null) {
            if (e.key == "price_asc") {
                setNameSort("Ceni rastuće")
            } else if (e.key == "price_desc") {
                setNameSort("Ceni opadajuće")
            } else if (e.key == "name_asc") {
                setNameSort("Nazivu rastuće")
            } else if (e.key == "name_desc") {
                setNameSort("Nazivu opadajuće")
            }
            dispatch(
                fetchPagProductsByPtName({
                    params: {
                        page: current,
                        size: pageSize,
                        sort_by: e.key,
                        pt_slug: getProductName()
                    }
                })
            );
        } else if (e.key == "none") {
            setNameSort("Poslednje dodati")
            dispatch(
                fetchPagProductsByPtName({
                    params: {
                        page: current,
                        size: pageSize,
                        pt_slug: getProductName()
                    }
                })
            );
        } else {
            setNameSort("Izaberi")
        }

    }
    const sort_menu = (
        <Menu onClick={(e) => handleMenuClick(e)}>
            <Menu.Item key="none" >
                <div className="products_view_sort_menu_item">
                    Poslednje dodati
                </div>
            </Menu.Item>
            <Menu.Item key="price_asc" >
                <div className="products_view_sort_menu_item">
                    Ceni rastuće
                    <ArrowUpOutlined />
                </div>
            </Menu.Item>
            <Menu.Item key="price_desc" >
                <div className="products_view_sort_menu_item">
                    Ceni opadajuće
                    <ArrowDownOutlined />
                </div>
            </Menu.Item>
            <Menu.Item key="name_asc" >
                <div className="products_view_sort_menu_item">
                    Nazivu rastuće
                    <ArrowUpOutlined />
                </div>
            </Menu.Item>
            <Menu.Item key="name_desc" >
                <div className="products_view_sort_menu_item">
                    Nazivu opadajuće
                    <ArrowDownOutlined />
                </div>
            </Menu.Item>
        </Menu>
    );
    const getTitle = () => {
        if (paramsList.length == 3) {
            return paramsList[2]
        } else if (paramsList.length == 2) {
            return paramsList[1]
        } else {
            return ""
        }
    }

    const getProductName = () => {
        if (paramsList.length == 3) {
            return paramsList[2]
        } else if (paramsList.length == 2) {
            return paramsList[1]
        } else {
            return ""
        }
    }
    const handleFilterClick = (e) => {
        // console.log(e)


    }
    
    const applyFilters = (e) => {
        dispatch(
            fetchPagProductsByPtName({
                params: {
                    page: current,
                    size: pageSize,
                    sort_by: typeSort,
                    pt_slug: getProductName(),
                    price_lower: priceSlider[0],
                    price_upper: priceSlider[1]
                }
            })
        );
    }

    const showDrawer = (e) => {
        // e.preventDefault()
        setVisible(true);
    };
    const onClose = (e) => {
        // e.preventDefault()
        setVisible(false);
        goToTop()
    };

    return (
        <Row justify="center" >
            <Col
                xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
                md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
                xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
            >
                <Row className="breadcrumb_path">
                    <ProductsBreadcrumb parameters={Object.values(params)}/>
                </Row>
                {
                    (isFetchProducts && products.data.totalItems === 0) &&
                    <div className="products_view_title">Trenutno ne postoje izabrani proizvodi.</div>
                }
                {
                    (isFetchProducts && products.data.totalItems !== 0) &&
                    <Row style={{ width: '100%' }}>
                        {device === 'mobile' ?
                            <div className="pv_mobile_filters">
                                <h1 className="products_view_title">{getTitle()}</h1>
                                <Button
                                    onClick={showDrawer}
                                    style={{ width: '20%' }}
                                >Filteri</Button>
                                <Drawer
                                    title={<div className="logo_link_menu">
                                        <img
                                            className="logo_div_menu"
                                            src={require(`../../assets/Logo/mobilni-logo.svg`).default}
                                        />
                                    </div>}
                                    placement={'left'}
                                    width={300}
                                    onClose={onClose}
                                    closable={true} //x za zatvaranje 
                                    visible={visible}
                                // className="mobile_menu_drawer"

                                >
                                    <FilterMenuProduct
                                        applyFilters={applyFilters}
                                        onClose={onClose}
                                        priceSlider={priceSlider}
                                        setPriceSlider={setPriceSlider}
                                    />
                                </Drawer>
                            </div>
                            :
                            <h1 className="products_view_title">{getTitle()}</h1>
                        }
                    </Row>
                }

                {
                    (isFetchProducts && products.data.totalItems !== 0) &&
                    <Row style={{ width: '100%' }} >
                        {device === 'mobile' ?
                            // <Col style={{ width: '100%' }}>
                            //     primenjeni filtri
                            // </Col>
                            <></>
                            :
                            <Col className="products_view_filters" >
                                <FilterMenuProduct
                                    applyFilters={applyFilters}
                                    onClose={onClose}
                                    priceSlider={priceSlider}
                                    setPriceSlider={setPriceSlider}
                                />
                            </Col>
                        }

                        <Col className="products_view_products"
                            style={{
                                width: device === 'mobile' ? '100%' : '79%',
                                margin: device !== 'mobile' ? '0% 0% 1% 1%' : '0% 0% 1% 0%'
                            }}>
                            <Row className="products_view_pagination">
                                <Col className="products_view_pagination_component">
                                    <Pagination
                                        defaultCurrent={1}
                                        pageSize={pageSize}
                                        current={current}
                                        total={isFetchProducts ? products.data.totalItems : 0}
                                        onChange={onShowSizeChange}
                                        showSizeChanger
                                        // showQuickJumper
                                        onShowSizeChange={onShowSizeChange}
                                        pageSizeOptions={[12, 24, 52, 100]}
                                        size={device === 'mobile' ? "small" : "default"}

                                    />
                                </Col>
                                <Col className="products_view_pagination_sort">
                                    <h2 className="products_view_pagination_sort_title">Sortiraj po:</h2>
                                    <Dropdown
                                        overlay={sort_menu}
                                        className="products_view_pagination_sort_dropdown_component"
                                    >
                                        <Button className="dugme">
                                            {nameSort}
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>

                                </Col>


                            </Row>
                            <Row className="produsts_view_list_product_card">
                                {
                                    isFetchProducts && products.data.data.map((x, index) =>
                                        <ProductCard
                                            type="product_view"
                                            product={x}
                                            key={x.product_id}
                                        />
                                    )
                                }


                            </Row>
                            <Row className="products_view_pagination">
                                <Col className="products_view_pagination_component">
                                    <Pagination
                                        defaultCurrent={1}
                                        pageSize={pageSize}
                                        current={current}
                                        total={isFetchProducts ? products.data.totalItems : 0}
                                        onChange={onShowSizeChange}
                                        showSizeChanger
                                        // showQuickJumper
                                        onShowSizeChange={onShowSizeChange}
                                        pageSizeOptions={[12, 24, 52, 100]}
                                        size={device === 'mobile' ? "small" : "default"}
                                    />
                                </Col>
                                <Col className="products_view_pagination_sort">
                                    <h2 className="products_view_pagination_sort_title">Sortiraj po:</h2>
                                    <Dropdown
                                        overlay={sort_menu}
                                        className="products_view_pagination_sort_dropdown_component"
                                    >
                                        <Button className="dugme" >
                                            {nameSort}
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>

                                </Col>


                            </Row>
                        </Col>
                    </Row>
                }

                <RecentlyAddedProducts />
            </Col>
        </Row>


    );
};

export default ProductsView;