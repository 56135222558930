import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu, Row, Col, Space, Divider, Input, Dropdown, Badge, Button, Image, Tooltip } from "antd";
import { ShoppingCartOutlined, UserOutlined, LoginOutlined, UserAddOutlined} from '@ant-design/icons';


import { CartHeader, MenuMobile, CategoryMenu } from '../my_components'
import { goToTop } from "../../utils";
import { fetchCategories } from "../../store/categories/actions";
import { categoriesSelector } from '../../store/my_selectors'
import './AppHeader.css'
import '../../styles/icons.css'

const AppHeader = () => {
    const history = useHistory()
    const token = localStorage.getItem('token');
    const roles = localStorage.getItem('roles');
    const [loggedUser, setloggedUser] = useState(false);
    const dispatch = useDispatch();
    const categories = useSelector(categoriesSelector);
    const isFetch = categories.isFetch;

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        // TODO : RESITI LOGOVANJE I REGISTRACIJU
        if (token && roles) {
            setloggedUser(true)
        } else {
            setloggedUser(false)
        }
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, [])
    useEffect(() => {
        if (isFetch === false) {
            dispatch(
                fetchCategories()
            );
        }
    }, []);

    const isSticky = (e) => {
        const scrollTop = window.scrollY;
        if (window.innerWidth <= 768) { //|| window.innerHeight <= 450
            const header2 = document.querySelector('.header_mobile');
            scrollTop >= 90 ? header2.classList.add('header_mobile_fixed') : header2.classList.remove('header_mobile_fixed');
        }
        else {
            const header = document.querySelector('.header3');
            scrollTop >= 133 ? header.classList.add('header3_fixed') : header.classList.remove('header3_fixed');
        }
    };

    const onSearch = value => console.log(value);

    function handleMenuClick(e) {
        // message.info('Click on menu item.');
    }

    // TODO 6B : OSTAVITI ZA KASNIJE 
    const user_menu = (
        <Menu onClick={handleMenuClick}>
            {loggedUser === false &&
                <Menu.Item key="user_menu_1" icon={<LoginOutlined />}>
                    <Divider type="vertical" />
                    <Link to="/prijava" onClick={goToTop} className="header_link">Prijavi se</Link>
                </Menu.Item>
            }
            {loggedUser === false &&
                <Menu.Item key="user_menu_2" icon={<UserAddOutlined />}>
                    <Divider type="vertical" />
                    <Link to="/registracija" onClick={goToTop} className="header_link">Registruj se</Link>
                </Menu.Item>
            }
            {loggedUser === true &&
                <Menu.Item key="user_menu_3" icon={<UserOutlined />}>
                    <Divider type="vertical" />
                    <Link to="/profil" onClick={goToTop} className="header_link">Profil</Link>
                </Menu.Item>
            }
            {/* {loggedUser === true &&
                <Menu.Item key="user_menu_3" icon={<LogoutOutlined />}>
                    <Divider type="vertical" />
                    <Link to="/" onClick={()=> {
                        // pozvati api za logout
                        localStorage.removeItem('token')
                        localStorage.removeItem('roles')
                        setloggedUser(false)
                        goToTop()
                    }} className="header_link">Odloguj se</Link>
                </Menu.Item>
            } */}

        </Menu>
    );

    return (
        <Layout.Header className="header">
            {/* TODO : SREDITI PRIKAZ HEADERA NA OSNOVU UREDJAJA I ROTACIJE, A NE NA OSNOVU CSS-A */}

            {/* DESKTOP PRVI HEADER */}
            <Row className="header1" justify="center" >
                <Col className="header_col"
                    //napraviti i za xs sm 
                    xs={{ span: 24 }} sm={{ span: 22 }}
                    md={{ span: 20 }} lg={{ span: 18 }}
                    xl={{ span: 18 }} xxl={{ span: 16 }}
                >
                    <Space className="header1_links" split={<Divider type="vertical" />}>
                        {/* TODO : KADA NAPRAVE STRANICE ZA NACIN PLACANJA I POLITIKA PRIVATNOSTI VRATITI LINKOVE */}
                        <Link  // to="/nacin-placanja"
                            to="/"
                            className="header_link" >Način plaćanja</Link>
                        <Link //to="/politika-privatnosti" 
                            to="/"
                            className="header_link">Politika privatnosti</Link>
                    </Space>
                    <Space className="header1_links" type="vertical" >
                        <Link to="/kontakt" className="header_link">Radno vreme</Link>
                    </Space>
                </Col>
            </Row>



            {/* DESKTOP DRUGI HEADER */}

            <Row className="header2" justify="center"  >

                <Col className="header_col"
                    xs={{ span: 24 }} sm={{ span: 22 }}
                    md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 16 }}
                >
                    {/* TODO : PROMENITI LOGO!!! */}
                    <Link to="/" className="logo_link">
                        <img
                            className="logo_div"
                            src={require(`../../assets/Logo/desktop-logo.svg`).default}
                        />
                    </Link>
                    {/* TODO : KADA SE PRETRAGA IMPLEMENTIRA */}
                    <Input.Search
                        placeholder="Pretraži.."
                        allowClear
                        className="search_box"
                        onSearch={onSearch}
                        display='none'
                    />
                    <Col className="header_icons" >
                        {/* TODO : PRIKAZATI KADA SE LOGIN I REGISTRACIJA OMOGUCE */}
                        <Tooltip title="Opcija u pripremi">
                            <Dropdown.Button
                                disabled={true}
                                overlay={user_menu}
                                placement="bottomRight"
                                icon={<UserOutlined className="icons" />}
                            ></Dropdown.Button>
                        </Tooltip>
                        {/* TODO : PRIKAZATI KADA SE KORPA OMOGUCI */}
                        <Tooltip title="Opcija u pripremi">
                            <Dropdown.Button
                                disabled={true}
                                // TODO : CART HEADER SREDITI 
                                overlay={<CartHeader />}
                                placement="bottomRight"
                                icon={
                                    <Badge
                                        // count={2}
                                        overflowCount={10}
                                    >
                                        <ShoppingCartOutlined className="icons" />
                                    </Badge>
                                }
                            ></Dropdown.Button>
                        </Tooltip>
                    </Col>
                </Col>


            </Row>


            {/* DESKTOP TRECI HEADER */}
            <Row className="header3" id="myHeader" justify="center">
                <Col className="header_col"
                    xs={{ span: 24 }} sm={{ span: 24 }}
                    md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 16 }}
                >
                    <Menu
                        mode="horizontal"
                        className="header_menu"
                    >

                        <CategoryMenu categories={categories.data} isFetchCategories={isFetch} />
                        <Link key="1" className="header_menu_item" to="/" onClick={goToTop}>Početna</Link>
                        <Link key="2" className="header_menu_item" to="/objave" onClick={goToTop}>Objave</Link>
                        <Link key="3" className="header_menu_item" to="/partneri" onClick={goToTop}>Partneri</Link>
                        {/* <Tooltip title="Opcija u pripremi" key="6" className="header_menu_item">Partneri</Tooltip> */}
                        <Link key="5" className="header_menu_item" to="/kontakt" onClick={goToTop}>Kontakt</Link>
                        {/* TODO : UBACITI KADA UBACE AKCIJE I KATALOG */}
                        {/* <Link key="3" className="header_menu_item" to="/akcije" onClick={goToTop}>Akcije</Link> */}
                        {/* <Link key="4" className="header_menu_item" to="/katalog" onClick={goToTop}>Katalog</Link> */}
                    </Menu>


                </Col>

            </Row>

            {/* MOBILNI MENI */}
            <div className="header_mobile" >
                <Row className="header_mobile_1" >
                    <Col className="header_col"
                        xs={{ span: 22 }} sm={{ span: 22 }}
                        md={{ span: 20 }} lg={{ span: 18 }}
                        xl={{ span: 18 }} xxl={{ span: 16 }}
                    >

                        <div className="logo_link_menu"
                            onClick={() => {
                                history.push('/')
                                goToTop()
                            }}
                        >
                            <img
                                className="logo_div_menu"
                                src={require(`../../assets/Logo/mobilni-logo.svg`).default}
                            />
                        </div>
                        <div className="mobile_icons" >

                            <div className="mobile_menu_icon">
                                <Tooltip title="Opcija u pripremi" className="tooltip_menu">
                                    <Dropdown
                                        overlay={user_menu}
                                        disabled={true}
                                        placement="bottomRight"
                                    >
                                        <Button>
                                            <Space>
                                                <UserOutlined className="mobile_icons_item" />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Tooltip>
                            </div>
                            <div className="mobile_menu_icon" >
                                <Tooltip title="Opcija u pripremi" className="tooltip_menu">
                                    <Dropdown
                                        overlay={<CartHeader />}
                                        disabled={true}
                                        placement="bottomRight"
                                    >
                                        <Button>
                                            <Space>
                                                <Badge
                                                    // count={2}
                                                    overflowCount={10}
                                                >
                                                    <ShoppingCartOutlined className="mobile_icons_item" />
                                                </Badge>
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Tooltip>

                            </div>

                        </div>


                    </Col>

                </Row>

                <Row className="header_mobile_2" justify="center">
                    <Col className="header_col"
                        xs={{ span: 22 }} sm={{ span: 22 }}
                        md={{ span: 20 }} lg={{ span: 18 }} xl={{ span: 18 }} xxl={{ span: 16 }}
                    >
                        <div className="header_2_mobile_icons" >
                            <div className="header_2_mobile_icon_menu">
                                <Menu
                                    mode="horizontal"
                                >
                                    <MenuMobile
                                        loggedUser={loggedUser}
                                        setloggedUser={setloggedUser}
                                        categories={categories.data}
                                        isFetchCategories={isFetch}
                                    />

                                </Menu>
                            </div>
                            <div className="header_2_mobile_icon_search" display='none'>
                                {/* TODO : KADA SE IMPLEMENTIRA PRETRAGA PROMENITI */}
                                <Input.Search
                                    placeholder="Pretraži.."
                                    allowClear
                                    // size="large"
                                    className="search_box_mobile"
                                    onSearch={onSearch}
                                    
                                />
                            </div>
                        </div>




                    </Col>


                </Row>
            </div>

        </Layout.Header>


    )
}

export default AppHeader