export const BASE_URL = process.env.REACT_APP_BASE_API;
export const API_BASE_URL = BASE_URL+'/api';
// SLIKE
export const API_IMAGES_URL = BASE_URL +"/storage/images/";
export const API_CATEGORIES_IMAGE_URL = API_IMAGES_URL +"categories/";
export const API_PRODUCT_TYPE_IMAGE_URL = API_IMAGES_URL + "product_types/";
export const API_PRODUCTS_IMAGE_URL = API_IMAGES_URL + "products/";
export const POSTS_IMG_URL = API_IMAGES_URL + 'posts/';
export const SLIDER_IMG_URL = API_IMAGES_URL + 'sliders/';
//HTML - POST
export const API_POST_URL = BASE_URL +"/storage/files/posts/";

// DATUMI
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
