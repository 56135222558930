import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Drawer, Row, Col, Tooltip } from "antd";
import { Link } from "react-router-dom"
import { MenuOutlined, HomeOutlined, SoundOutlined, TeamOutlined, ContactsOutlined,  LoginOutlined, LogoutOutlined, UserAddOutlined, PhoneOutlined, UserOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import '../header/AppHeader.css'
import './CategoryMenu.css'
import '../../styles/icons.css'

import { getBusinessHours, goToTop } from "../../utils";
import { MenuMobileChild } from '../my_components'
import { storeInfoSelector } from '../../store/my_selectors'

const MenuMobile = ({ loggedUser, setloggedUser, categories, isFetchCategories }) => {

    const storeInfo = useSelector(storeInfoSelector);
    const isFetch = storeInfo.isFetch;

    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
        goToTop()
    };

    const onLogout = () => {
        // TODO : pozvati api za logout
        localStorage.removeItem('token')
        localStorage.removeItem('roles')
        setVisible(false);
        setloggedUser(false)
        goToTop()
    }



    return (
        <div className="mobile_product_menu_nav">
            <MenuOutlined className="mobile_product_menu_space_icon" onClick={showDrawer} />

            <Drawer
                title={<div className="logo_link_menu">
                    <img
                        className="logo_div_menu"
                        src={require(`../../assets/Logo/mobilni-logo.svg`).default}
                    />
                </div>}
                placement={'left'}
                width={300}
                onClose={onClose}
                closable={true} //x za zatvaranje 
                visible={visible}
            >
                {/* TODO : SKLONITI HIDDEN KADA SE PRIJAVA RESI */}
                {loggedUser === false &&
                    <Row className="mobile_menu_row" hidden={true}>
                        <Col style={{ width: '15%' }}><LoginOutlined style={{ fontSize: 'large' }} /></Col>
                        <Col style={{ width: '85%' }}><Link to="/prijava" className="mobile_menu_link" onClick={onClose}>Prijavi se</Link></Col>
                    </Row>
                }
                {/* TODO : SKLONITI HIDDEN KADA SE REGISTRACIJA RESI */}
                {loggedUser === false &&
                    <Row className="mobile_menu_row" hidden={true}>
                        <Col style={{ width: '15%' }}><UserAddOutlined style={{ fontSize: 'large' }} /></Col>
                        <Col style={{ width: '85%' }}><Link to="/registracija" className="mobile_menu_link" onClick={onClose}>Registruj se</Link></Col>
                    </Row>
                }
                {/* TODO : SKLONITI HIDDEN KADA SE PRIKAZ PROFILA RESI */}
                {loggedUser === false &&
                    <Row className="mobile_menu_row" hidden={true}>
                        <Col style={{ width: '15%' }}><UserOutlined style={{ fontSize: 'large' }} /></Col>
                        <Col style={{ width: '85%' }}><Link to="/profil" className="mobile_menu_link" onClick={onClose}>Profil</Link></Col>
                    </Row>
                }
                {/* TODO : SKLONITI HIDDEN KADA SE ODJAVA RESI */}
                {loggedUser === false &&
                    <Row className="mobile_menu_row" hidden={true}>
                        <Col style={{ width: '15%' }}><LogoutOutlined style={{ fontSize: 'large' }} /></Col>
                        <Col style={{ width: '85%' }}><Link to="/" className="mobile_menu_link" onClick={onLogout}>Odloguj se</Link></Col>
                    </Row>
                }
                {/* TODO : SKLONITI HIDDEN KADA SE KORPA RESI */}
                <Row className="mobile_menu_row" hidden={true}>
                    <Col style={{ width: '15%' }}><ShoppingCartOutlined  style={{ fontSize: 'large' }} /></Col>
                    <Col style={{ width: '85%' }}><Link to="/korpa" className="mobile_menu_link" onClick={onClose}>Korpa</Link></Col>
                </Row>

                <Row className="mobile_menu_row_title">
                    <p className="mobile_menu_drawer_title">Navigacija</p>
                </Row>
                <Row className="mobile_menu_row" style={{ width: '100%'}}>
                    <Col style={{ width: '15%' }}><HomeOutlined  style={{ fontSize: '25px', color: 'black' }} /></Col>
                    <Col style={{ width: '85%' }}><Link className="mobile_menu_link" to="/" onClick={onClose}>Početna</Link></Col>
                </Row>
                <Row className="mobile_menu_row" style={{ width: '100%'}}>
                    <Col style={{ width: '15%' }}><MenuOutlined   style={{ fontSize: '25px', color: 'black' }} /></Col>
                    <Col style={{ width: '85%' }}><Link className="mobile_menu_link" to="/proizvodi" onClick={onClose}>Kategorije</Link></Col>
                </Row>
                <Row className="mobile_menu_row" style={{ width: '100%'}}>
                    <Col style={{ width: '15%' }}><SoundOutlined  style={{ fontSize: '25px', color: 'black' }} /></Col>
                    <Col style={{ width: '85%' }}><Link className="mobile_menu_link" to="/objave" onClick={onClose}>Objave</Link></Col>
                </Row>
                <Row className="mobile_menu_row" style={{ width: '100%'}}>
                    <Col style={{ width: '15%' }}><TeamOutlined   style={{ fontSize: '25px', color: 'black' }} /></Col>
                    <Col style={{ width: '85%' }}><Link className="mobile_menu_link" to="/partneri" onClick={onClose}>Partneri</Link></Col>
                </Row>
                <Row className="mobile_menu_row" style={{ width: '100%'}}>
                    <Col style={{ width: '15%' }}><ContactsOutlined   style={{ fontSize: '25px', color: 'black' }} /></Col>
                    <Col style={{ width: '85%' }}><Link className="mobile_menu_link" to="/kontakt" onClick={onClose}>Kontakt</Link></Col>
                </Row>
                {/* TODO : DODATI KADA SE NAPRAVE STRANICA AKCIJE*/}
                {/* <Row className="mobile_menu_row">
                    <Link className="mobile_menu_link" to="/akcije" onClick={onClose}>Akcije</Link>
                </Row>
                {/* TODO : DODATI KADA SE NAPRAVE STRANICA KATALOG*/}
                {/*
                <Row className="mobile_menu_row">
                    <Link className="mobile_menu_link" to="/katalog" onClick={onClose}>Katalog</Link>
                </Row> */}

                <Row className="mobile_menu_row_title">
                    <p className="mobile_menu_drawer_title">Svi proizvodi</p>
                </Row>
                {
                    isFetchCategories && categories.map((category, category_key) =>
                        <MenuMobileChild
                            category={category}
                            onClose={onClose}
                            key={category.pc_name}
                        />
                    )
                }


                <Row className="mobile_menu_row_title">
                    <p className="mobile_menu_drawer_title">Telefon</p>
                </Row>
                {isFetch && storeInfo.data.telephone.map((x, y) =>
                    <Row key={y}>
                        <Col style={{ width: '15%' }}>
                            <PhoneOutlined style={{ fontSize: 'large' }} />
                        </Col>
                        <Col style={{ width: '85%' }}>
                            <h4 className="mobile_menu_link">
                                <div >
                                    <a href={"tel:" + x.tel_num} className="mobile_menu_link">{x.owner} | {x.tel_num}</a>
                                    <br />
                                </div>

                            </h4>
                        </Col>
                    </Row>
                )}

                <Row className="mobile_menu_row_title">
                    <p className="mobile_menu_drawer_title">Radno vrijeme</p>
                </Row>
                {
                    isFetch &&
                    storeInfo.data.business_hours.map((x, y) =>
                        <Row key={y}>
                            <h4 className="mobile_menu_link" >
                                {getBusinessHours(x)}
                            </h4>
                        </Row>

                    )
                }

            </Drawer>
        </div>
    );
}

export default MenuMobile