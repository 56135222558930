import React from "react"
import {  Row, Col } from "antd";
import { truncateString } from "../../utils";
import './BanersCard.css';

const BanersCard = ({ title, description, picture }) => {
    return (
        <div className="baners_card">
            <Row>
                <Col className="baners_card_col1">
                    <img
                        className="baners_card_picture"
                        // TODO : RESITI NA DRUGACIJI NACIN
                        src={require(`../../assets/Baners/${picture}`).default}
                        alt={title}
                    />
                </Col>
                <Col className="baners_card_col2">
                    <Row>
                        <h1 className="baners_card_title">{title}</h1>
                    </Row>
                    <Row>
                        <h2 className="baners_card_subtitle">{truncateString(description, 50)}</h2>
                    </Row>
                    {/* <Row className="baners_card_row3">
                        <Button className="baners_card_button">POGLEDAJ VIŠE</Button>
                    </Row> */}

                </Col>
            </Row>
        </div>
    );
}

export default BanersCard;