import React, { useState } from "react";
import {  Col, Row } from 'antd';
import AppBreadcrumb from "../breadcrumb/AppBreadcrumb";
// import { Document, Page, pdfjs } from 'react-pdf';
import {  pdfjs } from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// TODO : UCITATI KATALOG 
// NE KORISTI SE 
const Catalog = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (

    <Row justify="center" >

      <Col
        // className="category_page_list"
        xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
        md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
        xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
      >
        <Row className="breadcrumb_path">
          <AppBreadcrumb />
        </Row>
        <Row>
          <h1 className="page_under_construction">--------- Stranica je u izradi -----------</h1>
        </Row>
        {/* <Row>
          <Document file={require(`../../assets/InfocompKatalog.pdf`).default} onLoadSuccess={onDocumentLoadSuccess} >
            <Page pageNumber={pageNumber} />
          </Document>

        </Row>
        <Row>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </Row> */}




      </Col>
    </Row>

  );
}
                                                                                                                                                                                   
export default Catalog;
