import { take, put, call, select } from 'redux-saga/effects';
import { message } from "antd";


import { PUT_TO_CART, FETCH_CART } from './constants';
import { putToCart } from './reducer/computation-functions';

import apiCall, { API_PATHS } from '../../services/ApiCall';


export function* fetchCartFunction() {
    const { payload } = yield take(FETCH_CART)
    yield put(putToCart({'isFetch': false}))
    const data = yield call(apiCall, 'GET', API_PATHS.API_CART, payload);
    yield put(putToCart({
        'data': data.data.data,
        'isFetch': true
    }))
}

// TODO : end users
export function* putToCartFunction() {
    const { payload } = yield take(PUT_TO_CART);
    // doraditi
    message.info('Proizvod je dodat u korpu!');
}