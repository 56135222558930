import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Col, Row, Image } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import AppBreadcrumb from "../breadcrumb/AppBreadcrumb";
import './Cart.css';
import { goToTop } from "../../utils";
import { useHistory } from "react-router-dom";

// TODO : SREDITI OVU KOMPONENTU, PODELITI U MANJE KOMPONENTE
const Cart = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }
  useEffect(() => {
    window.addEventListener("DOMContentLoaded", handleResize)
    window.addEventListener("click", handleResize)
    window.addEventListener("resize", handleResize)
    return () => {
        window.removeEventListener("DOMContentLoaded", handleResize)
        window.removeEventListener("click", handleResize)
        window.removeEventListener("resize", handleResize)
    }
}, [])

  return (
    <Row justify="center" style={{ marginBottom: "1%" }}>
      <Col
        className="cart_col"
        xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
        md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
        xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
      >
        <Row className="cart_breadcrumb_path">
          <AppBreadcrumb />
        </Row>
        {isMobile ?
          <div >
            <Row className="cart_row_mobile">
              <Col className="cart_mobile_1">
                <Row className="cart_mobile_13">Proizvod 1</Row>
                <Row className="cart_mobile_12">
                  <Image
                    // src={require(`../../assets/Cart/${s.putanja}.png`).default}
                    src={require(`../../assets/Cart/navigacija.png`).default}
                    className="cart_mobile_image"
                    preview={false}

                  // key={index}
                  />

                </Row>
                {/* <Row className="cart_mobile_14">Nam velit ipsum, hendrerit non augue ac, faucibus congue libero. Fusce dui augue, facilisis sit amet ante sollicitudin, maximus lacinia libero. Morbi nunc massa, luctus ac nisl in, maximus scelerisque massa. Sed et sem lacus. Donec blandit purus in eros cursus mollis. Nulla congue pellentesque tincidunt.</Row> */}
              </Col>
              <Col className="cart_mobile_2">
                <Row className="cart_mobile_21">
                  <Col className="cart_mobile_211">Pojedinacna cijena</Col>
                  <Col className="cart_mobile_212">KM 1,699.00</Col>
                </Row>
                <Row className="cart_mobile_22">
                  <Col className="cart_mobile_221">Količina</Col>
                  <Col className="cart_mobile_222">
                    <div onClick={() => { console.log("smanjenje") }}>
                      <MinusOutlined className="cart_minus_plus" />
                    </div>
                    <div className="cart_mobile_222_box">4</div>
                    <div onClick={() => { console.log("povecanje") }}>
                      <PlusOutlined className="cart_minus_plus" />
                    </div>
                  </Col>
                </Row>
                <Row className="cart_mobile_23">
                  <Col className="cart_mobile_231">Ukupno</Col>
                  <Col className="cart_mobile_232">KM 1,699.00

                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="cart_row_mobile">
              <Col className="cart_mobile_1">
                <Row className="cart_mobile_13">Proizvod 1</Row>
                <Row className="cart_mobile_12">
                  <Image
                    // src={require(`../../assets/Cart/${s.putanja}.png`).default}
                    src={require(`../../assets/Cart/navigacija.png`).default}
                    className="cart_mobile_image"
                    preview={false}

                  // key={index}
                  />

                </Row>
                {/* <Row className="cart_mobile_14">Nam velit ipsum, hendrerit non augue ac, faucibus congue libero. Fusce dui augue, facilisis sit amet ante sollicitudin, maximus lacinia libero. Morbi nunc massa, luctus ac nisl in, maximus scelerisque massa. Sed et sem lacus. Donec blandit purus in eros cursus mollis. Nulla congue pellentesque tincidunt.</Row> */}
              </Col>
              <Col className="cart_mobile_2">
                <Row className="cart_mobile_21">
                  <Col className="cart_mobile_211">Pojedinacna cijena</Col>
                  <Col className="cart_mobile_212">KM 1,699.00</Col>
                </Row>
                <Row className="cart_mobile_22">
                  <Col className="cart_mobile_221">Količina</Col>
                  <Col className="cart_mobile_222">
                    <div onClick={() => { console.log("smanjenje") }}>
                      <MinusOutlined className="cart_minus_plus" />
                    </div>
                    <div className="cart_mobile_222_box">4</div>
                    <div onClick={() => { console.log("povecanje") }}>
                      <PlusOutlined className="cart_minus_plus" />
                    </div>
                  </Col>
                </Row>
                <Row className="cart_mobile_23">
                  <Col className="cart_mobile_231">Ukupno</Col>
                  <Col className="cart_mobile_232">KM 1,699.00

                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          :
          <div>

            <Row className="cart_row_desktop">
              <Col className="cart_col1"></Col>
              <Col className="cart_col2">Naziv proizvoda</Col>
              <Col className="cart_col3">Pojedinacna cijena</Col>
              <Col className="cart_col4">Količina</Col>
              <Col className="cart_col5">Ukupno</Col>
            </Row>
            {/* ponavlja se ovaj row sledeci */}
            <Row className="cart_row_desktop">
              <Col className="cart_col20">
                <Row>
                  <Col className="cart_col21">
                    <Image
                      // src={require(`../../assets/Cart/${s.putanja}.png`).default}
                      src={require(`../../assets/Cart/navigacija.png`).default}
                      className="cart_col_image"
                      preview={false}

                    // key={index}
                    />
                  </Col>
                  <Col className="cart_col22">
                    <Row className="cart_col22_title">
                      Proizvod 1
                    </Row>
                    <Row className="cart_col22_text">
                      Nam velit ipsum, hendrerit non augue ac, faucibus congue libero. Fusce dui augue, facilisis sit amet ante sollicitudin, maximus lacinia libero. Morbi nunc massa, luctus ac nisl in, maximus scelerisque massa. Sed et sem lacus. Donec blandit purus in eros cursus mollis. Nulla congue pellentesque tincidunt.
                    </Row>

                  </Col>
                </Row>
              </Col>

              <Col className="cart_col23">KM 1,699.00</Col>
              <Col className="cart_col24">
                <div
                  onClick={() => {
                    // console.log("smanjenje")
                  }}
                >
                  <MinusOutlined className="cart_minus_plus"
                  />
                </div>

                <div className="cart_col24_box">4</div>
                <div
                  onClick={() => {
                    // console.log("povecanje")
                  }}
                >
                  <PlusOutlined className="cart_minus_plus" />
                </div>

              </Col>
              <Col className="cart_col25">KM 1,699.00</Col>
            </Row>
            <Row className="cart_row_desktop">
              <Col className="cart_col20">
                <Row>
                  <Col className="cart_col21">
                    <Image
                      // src={require(`../../assets/Cart/${s.putanja}.png`).default}
                      src={require(`../../assets/Cart/navigacija.png`).default}
                      className="cart_col_image"
                      preview={false}

                    // key={index}
                    />
                  </Col>
                  <Col className="cart_col22">
                    <Row className="cart_col22_title">
                      Proizvod 1
                    </Row>
                    <Row className="cart_col22_text">
                      Nam velit ipsum, hendrerit non augue ac, faucibus congue libero. Fusce dui augue, facilisis sit amet ante sollicitudin, maximus lacinia libero. Morbi nunc massa, luctus ac nisl in, maximus scelerisque massa. Sed et sem lacus. Donec blandit purus in eros cursus mollis. Nulla congue pellentesque tincidunt.
                    </Row>

                  </Col>
                </Row>
              </Col>

              <Col className="cart_col23">KM 1,699.00</Col>
              <Col className="cart_col24">
                <div
                  onClick={() => {
                    // console.log("smanjenje")
                  }}
                >
                  <MinusOutlined className="cart_minus_plus"
                  />
                </div>

                <div className="cart_col24_box">4</div>
                <div
                  onClick={() => {
                    // console.log("povecanje")
                  }}
                >
                  <PlusOutlined className="cart_minus_plus" />
                </div>

              </Col>
              <Col className="cart_col25">KM 1,699.00</Col>
            </Row>
          </div>
        }
        {/* dugmici  */}
        <Row className="cart_row">
          <Col className="cart_button_empty">Isprazni korpu</Col>
          <Col className="cart_button_refresh">Osveži korpu</Col>
          <Col className="cart_button_continue">Nastavi kupovinu</Col>
        </Row>

        <Row className="cart_row">
          <Col className="cart_discount_code" >
            <Row className="cart_discount_code_1">Kod za popust</Row>
            <Row className="cart_discount_code_2"><Input></Input></Row>
            <Row className="cart_discount_code_3">
              <Button className="cart_discount_code_3_button">ISKORISTITE KUPON</Button>
            </Row>
          </Col>
          <Col className="cart_total_price">
            <Row className="cart_total_price_1">
              <Col className="cart_total_price_tiny">Ukupno</Col>
              <Col className="cart_total_price_bold">KM 1699,00</Col>
            </Row>
            <Row className="cart_total_price_1">
              <Col className="cart_total_price_tiny">PDV</Col>
              <Col className="cart_total_price_bold">KM 1.00</Col>
            </Row>
            <Row className="cart_total_price_2">
              <Col className="cart_total_price_bold">Sve ukupno</Col>
              <Col className="cart_total_price_bold">KM 1700.00</Col>
            </Row>
            <Row className="cart_total_price_3">
              <Button className="cart_total_price_3_button"
                onClick={() => {
                  goToTop()
                  history.push(`/kupovina`)
                }}
              >IDI NA KASU</Button>
            </Row>
          </Col>
        </Row>

      </Col>
    </Row>
  );
}

export default Cart;