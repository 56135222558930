import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';

import './ProductDetails.css'
import '../header/AppHeader.css'
import { productDetailSelector, categoriesSelector } from '../../store/my_selectors'

import { fetchProductDetailById } from "../../store/products/actions";
import ProductDetailsComponent from "./ProductDetailsComponent";

const ProductDetails = () => {
    const params = useParams();

    const dispatch = useDispatch();
    const productDetail = useSelector(productDetailSelector);
    const isFetchProduct = productDetail.isFetch;

    useEffect(() => {
        dispatch(
            fetchProductDetailById(params.product_name)
        );
        setCoverImagePath("");
    }, [params.product_name]);

    const [coverImagePath, setCoverImagePath] = useState("")
    const [quantityOfProduct, setQuantityOfProduct] = useState(0);
    const [informationBarProm, setInformationBarProm] = useState(1);

    // TODO videti kako proizvod moze da izgleda kada Miroslav salje 
    const giveComment = () => {
        setInformationBarProm(2)
        document.getElementById("give_comment_row").scrollIntoView(false)
    }
    const categories = useSelector(categoriesSelector)

    const returnPathFromCategories = (cat_name, subcat_name, pt_name) => {
        let category, subcategory, product_type = null
        if (categories.isFetch) {
            if (cat_name != null) {
                category = categories.data.filter(x => x.pc_name == cat_name)[0]
            }
            if (subcat_name != null) {
                subcategory = categories.data.filter(x => x.pc_name == cat_name)[0].children.filter(y => y.pc_name == subcat_name)[0]
            }
            if (pt_name != null) {
                product_type = categories.data.filter(x => x.pc_name == cat_name)[0].children.filter(y => y.pc_name == subcat_name)[0].children.filter(z => z.pt_name == pt_name)[0]
            }
        }

        return [category, subcategory, product_type]
    }

    // TODO popraviti breadcrumb
    // DEPRECATED
    const returnAllPath = () => {
        let result = []
        if (productDetail.isFetch && categories.isFetch) {

            result = [productDetail.data.category_slug, productDetail.data.subcategory_slug, productDetail.data.pt_slug, productDetail.data.product_name]

        }

        return result
    }

    const addToChart = () => {
    }  

    return (
        <ProductDetailsComponent
            isFetchProduct={isFetchProduct}
            productDetail={isFetchProduct ? productDetail : null}
            returnAllPath={returnAllPath}
            coverImagePath={coverImagePath}
            setCoverImagePath={setCoverImagePath}
            informationBarProm={informationBarProm}
            setInformationBarProm={setInformationBarProm}

        />
    );
};

export default ProductDetails;