export function putPost(state, payload) {
    return {
        ...state,
        post: payload
    };
}
export function putIndividualPost(state, payload) {
    return {
        ...state,
        individualPost: payload
    };
}