import React from "react"
import { Row, Col, Menu, Button, Image } from "antd";
import { Link, useHistory } from "react-router-dom"
import { goToTop, truncateString } from "../../utils";
import './CategoryPage.css'
import { API_PRODUCT_TYPE_IMAGE_URL } from "../../config";
import PhotoDisplayHandler from "../general/PhotoDisplayHandler";

const ProductTypePageItem = ({ product_type, category, subcategory }) => {
    const history = useHistory();

    return (
        <Row className="category_page_item" onClick={()=> {
            goToTop()
            history.push(`/proizvodi/${category}/${subcategory}/${product_type.slug}`)
        }}>
            <Col className="category_page_item_icon">
                <PhotoDisplayHandler
                    img_name={product_type.img_name}
                    dir="product_types"
                    calculate={false}
                    img_class_name="product_type_page_item_box_image"
                    img_alt={product_type.pt_name}
                />
            </Col>
            <Col className="category_page_item_name">
                <div>{truncateString(product_type.pt_name, 50)}</div>
            </Col>
        </Row>

    );
}

export default ProductTypePageItem;