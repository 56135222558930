import React from "react"
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Col, Row } from "antd"

import { SlideshowAd, RecentlyAddedProducts, ProductsBreadcrumb, CategoryPageItem} from '../my_components'
import { categoriesSelector } from '../../store/my_selectors'

import './CategoryPage.css'

const CategoryPage = () => {
    const params = useParams();
    const categories = useSelector(categoriesSelector);
    return (
        <Col>
            <Row>
                {/* REKLAMA */}
                <SlideshowAd/>  

            </Row>

            <Row justify="center" >

                <Col
                    className="category_page_list"
                    xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
                    md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
                    xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
                >
                    <Row className="category_page_breadcrumb" >
                        <ProductsBreadcrumb parameters={Object.values(params)}/>
                    </Row>


                    <Row>
                        <h1 className="category_page_title">Izaberi kategoriju</h1>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        {
                            categories.isFetch && categories.data.map((category, category_key) =>
                                <CategoryPageItem
                                    category={category}
                                    key={category_key}
                                />
                            )
                        }
                    </Row>
                    <Row>
                    <RecentlyAddedProducts />
                    <div className="break"></div> 
                    </Row>
                    
                </Col>

            </Row>
        </Col>

    );
}

export default CategoryPage;