import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";

const AppBreadcrumb = () => {
    const location = useLocation();
    const pathnamemap = location.pathname.split("/")
    return (
        <Breadcrumb>

            {pathnamemap.map((x, y) => {
                let putanja = "";
                for (var i = 1; i <= y; i++) {
                    putanja = putanja + "/" + pathnamemap[i]

                }

                if (y === 0) {
                    return <Breadcrumb.Item key={y} ><Link to="/">Početna</Link> </Breadcrumb.Item>
                }else {
                    let ispis = ""
                    // if (x == "nacin-placanja") {
                    //     ispis = "Način plaćanja"
                    // }else if (x == "politika-privatnosti") {
                    //     ispis = "Politika privatnosti"
                    // }else 
                    if (x == "profil") {
                        ispis = "Profil"
                    }else if (x == "korpa") {
                        ispis = "Korpa"
                    }else if (x == "objave") {
                        ispis = "Objave"
                    // }else if (x == "akcije") {
                    //     ispis = "Akcije"
                    // }else if (x == "katalog") {
                    //     ispis = "Katalog"
                    }else if (x == "partneri") {
                        ispis = "Partneri"
                    }else if (x == "kontakt") {
                        ispis = "Kontakt"
                    }else {
                        ispis = ""
                    }
                    return <Breadcrumb.Item key={y}><Link to={putanja}>{ispis}</Link></Breadcrumb.Item>
                }

            })
            }
        </Breadcrumb>
    );
};

export default AppBreadcrumb;