import React from "react";
import { Row, Col, Form } from 'antd';
import './Shopping.css';

const Shopping = () => {

    return (
        <Row justify="center" style={{ marginBottom: "1%" }}>
            <Col
                className="shopping_col"
                xs={{ span: 22, offset: 0 }} sm={{ span: 22, offset: 0 }}
                md={{ span: 20, offset: 0 }} lg={{ span: 18, offset: 0 }}
                xl={{ span: 18, offset: 0 }} xxl={{ span: 16, offset: 0 }}
            >
                <Row >
                    <h1 className="shopping_title">Završite kupovinu</h1>
                </Row>
                <Row className="shopping_box">
                    <Col className="shopping_box_menu">
                        <Row className="shopping_box_menu_row">
                            <Col className="shopping_box_menu_button_3">
                                <Row className="shopping_box_menu_icon"><div className="shopping_box_menu_number">1</div></Row>
                                <Row className="shopping_box_menu_text">Adresa plaćanja</Row>
                            </Col>
                            <Col className="shopping_box_menu_button_2">
                                <Row className="shopping_box_menu_icon"><div className="shopping_box_menu_number">2</div></Row>
                                <Row className="shopping_box_menu_text">Adresa dostave</Row>
                            </Col>
                            <Col className="shopping_box_menu_button_1">
                                <Row className="shopping_box_menu_icon"><div className="shopping_box_menu_number">3</div></Row>
                                <Row className="shopping_box_menu_text">Način dostave</Row>
                            </Col>
                            <Col className="shopping_box_menu_button_1">
                                <Row className="shopping_box_menu_icon"><div className="shopping_box_menu_number">4</div></Row>
                                <Row className="shopping_box_menu_text">Način plaćanja</Row>
                            </Col>
                            <Col className="shopping_box_menu_button_1">
                                <Row className="shopping_box_menu_icon"><div className="shopping_box_menu_number">5</div></Row>
                                <Row className="shopping_box_menu_text">Pregled narudžbine</Row>
                            </Col>
                        </Row>
                        {/* prvi sadrzaj */}
                        <Row>
                            {/* slicno kao login form iz antd */}
                           
                            <Form
                                name="basic"
                               
                                autoComplete="off"
                                className="shopping_form"
                                layout="vertical"
                            >

                                <h1 
                                
                                >Podaci o adresi </h1>
                            </Form>
                        </Row>
                    </Col>
                </Row>

            </Col>
        </Row>
    );
}

export default Shopping;