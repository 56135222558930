import { all, spawn, call } from 'redux-saga/effects';
import flatten from 'lodash/flatten';

import * as cartSaga from './cart/saga';
import * as storeInfoSaga from './storeInfo/saga';
import * as categoriesSaga from './categories/saga';
import * as productsSaga from './products/saga';
import * as sliderSaga from './slider/saga';
import * as postSaga from './post/saga';

export default function* rootSaga() {
  let sagas = flatten(
    [
      cartSaga,
      storeInfoSaga,
      categoriesSaga,
      productsSaga,
      sliderSaga,
      postSaga
    ].map(saga => Object.keys(saga).map(sagaFunctionName => saga[sagaFunctionName]))
  );
  yield all(
    sagas.map(saga =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
          } catch (e) {
            // mogu da stavim pop up messages
            // console.error(e.response.data);
          }
        }
      })
    )
  );

}