import { take, put, call } from 'redux-saga/effects';


import { FETCH_SLIDER } from './constants';
import { putSlider } from './actions';
import apiCall, { API_PATHS } from '../../services/ApiCall';

export function* fetchSliderDataFunction() {
    const { payload } = yield take(FETCH_SLIDER);
    const data = yield call(apiCall, 'GET', API_PATHS.API_SLIDER, payload);
    yield put(putSlider({
        'data': data.data.data,
        'isFetch': true
    }));
}
