import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Col, message } from 'antd';
import { useHistory } from "react-router-dom";
import '../../styles/form/form.css';
import apiCall, { API_PATHS } from '../../services/ApiCall';

const Login = () => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const roles = localStorage.getItem('roles');
    if (token && roles) {
      history.push('/home');
    }
  }, []);

  const onFinish = async (payload) => {
    let response = await apiCall('GET', API_PATHS.API_LOGIN, payload);
    if (response.status == 200) {
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('roles', JSON.stringify(response.data.roles));
      message.info('Uspešno ste se prijavili!')
      history.push('/');
    } else {
      message.error(response.data.message);
    }

    // console.info('Success')
  };

  const onFinishFailed = (errorInfo) => {
    //TODO dodati ogranicenja ili neko obavestenje
    message.error('Niste dobro popunili polja za prijavu.')
    // console.error('Niste dobro popunili polja za prijavu.')
  };

  return (
    <Col
      className="app_col_center"
      xs={{ span: 20, offset: 1 }} sm={{ span: 20, offset: 2 }}
      md={{ span: 17, offset: 3 }} lg={{ span: 17, offset: 3 }} xl={{ span: 17, offset: 3 }} xxl={{ span: 17, offset: 3 }}
    >
      <Form
        name="basic"
        initialValues={{
          // remember: false,
          email: undefined, 
          password: undefined
        }}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 22,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="login_form"
        layout="vertical"
      >
        <h1 className="login_form_title">Prijavi se </h1>

        <Form.Item
          label="Email adresa"
          name="email"
          className="login_form_item"
          rules={[
            {
              required: true,
              type: "email",
              message: 'Unesite svoju email adresu!',
            },
          ]}
        >
          <Input className="login_form_item_input" />
        </Form.Item>

        <Form.Item
          label="Lozinka"
          name="password"
          className="login_form_item"
          rules={[
            {
              required: true,
              message: 'Unesite svoju lozinku!',
            },
          ]}
        >
          <Input.Password className="login_form_item_input" />
        </Form.Item>
        {/* NE TREBA ZA SADA */}
        {/* <Form.Item
          name="remember"
          valuePropName="checked"
          className="login_form_item_checkbox"
          wrapperCol={{
            offset: 0,
            span: 16,
          }}
        >
          <Checkbox
            className="login_form_item_checkbox_input"
          >Zapamti podatke za prijavu</Checkbox>
        </Form.Item> */}

        <Form.Item
          wrapperCol={{
            offset: 0,
            span: 22,
          }}
          className="login_form_item"
        >
          <Button type="primary" htmlType="submit" className="login_form_item_button">
            PRIJAVI SE
          </Button>
        </Form.Item>
        <h3 className="login_form_forget_password_text">Zaboravili ste lozinku?</h3>
        <h2 className="login_form_is_new_text">Da li ste novi ovde?</h2>
        <Form.Item
          wrapperCol={{
            offset: 0,
            span: 22,
          }}
          className="login_form_item"
        >
          <Button
            className="login_form_registration_button"
            onClick={() => history.push(`/registracija`)}
          >
            Registruj se
          </Button>
        </Form.Item>
      </Form>

    </Col>

  );
};

export default Login;
