import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Col, Row, Image, Menu } from 'antd';
import { useHistory } from "react-router-dom";

import { goToTop } from "../../utils";

import './CartHeader.css'

const CartHeader = () => {
    const history = useHistory()

    return (
        <Menu className="cart_menu_div">
            <Row >
                <h3 className="cart_menu_title">Nedavno dodati proizvodi</h3>
            </Row>
            <Row className="cart_menu_item">
                <Col>
                    <Menu.Item className="cart_menu_item_num" >
                        <Row className="cart_menu_item_row">
                            <Col className="cart_menu_item_col_1">
                                <Image
                                    src={require(`../../assets/Cart/navigacija.png`).default}
                                    width={'90%'}
                                    height={'auto'}
                                    preview={false}
                                ></Image>
                            </Col>
                            <Col className="cart_menu_item_col_2">
                                <Row className="cart_menu_item_21">ABKONCORE B1000R REAL5.2, Slušalice</Row>
                                <Row className="cart_menu_item_22">
                                    <div className="cart_menu_item_number_box">2</div>
                                    <h4 className="cart_menu_item_price"> x 185.00 KM </h4>
                                </Row>
                            </Col>
                            <Col className="cart_menu_item_col_3">
                                <div className="cart_menu_item_x_button">x</div>
                            </Col>
                        </Row>
                    </Menu.Item>
                    <Menu.Item className="cart_menu_item_num" >
                        <Row className="cart_menu_item_row">
                            <Col className="cart_menu_item_col_1">
                                <Image
                                    src={require(`../../assets/Cart/navigacija.png`).default}
                                    width={'100%'}
                                    height={'auto'}
                                    preview={false}
                                ></Image>
                            </Col>
                            <Col className="cart_menu_item_col_2">
                                <Row className="cart_menu_item_21">ABKONCORE B1000R REAL5.2, Slušalice</Row>
                                <Row className="cart_menu_item_22">
                                    <div className="cart_menu_item_number_box">2</div>
                                    <h4 className="cart_menu_item_price"> x 185.00 KM </h4>
                                </Row>
                            </Col>
                            <Col className="cart_menu_item_col_3">
                                <div className="cart_menu_item_x_button">x</div>
                            </Col>
                        </Row>
                    </Menu.Item>
                </Col>
            </Row>
            <Row className="cart_menu_total">
                <h4 >UKUPNO: 892.00 KM</h4>
            </Row>
            <Row className="cart_menu_buttons">
                <Button className="cart_menu_buttons1" onClick={() => {
                    goToTop()
                    history.push(`/korpa`)
                }}>Pogledaj korpu</Button>
                <Button className="cart_menu_buttons2" onClick={() => {
                    goToTop()
                    history.push(`/kupovina`)
                }}>Završite kupovinu</Button>
            </Row>

        </Menu>
    );
}

export default CartHeader;