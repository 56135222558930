import { BrowserRouter as Router } from "react-router-dom";
import { Layout, BackTop } from "antd";
import './App.css';

import { AppHeader, AppFooter, AppContent } from "../my_components"

const App = () => {

  return (
    <Router>
      <Layout className="app_layout">
        <AppHeader />
        <AppContent/>
        <AppFooter />
      </Layout>
      <BackTop />
    </Router>

  );
}

export default App;
