import {
    FETCH_DEVICE_DATA,
    PUT_TO_DEVICE_DATA
} from './constants';

export const fetchDeviceDataAction = payload => ({
    type: FETCH_DEVICE_DATA,
    payload
});
export const putToDeviceDataAction = payload => ({
    type: PUT_TO_DEVICE_DATA,
    payload
});

