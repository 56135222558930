import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'antd';

import { PhotoDisplayHandler } from '../my_components'
import { fetchSlider } from "../../store/slider/actions";
import { deviceSelector, sliderSelector } from '../../store/my_selectors'

import './SlideshowAd.css'

const SlideshowAd = () => {
    const dispatch = useDispatch();
    const sliderData = useSelector(sliderSelector);
    const deviceData = useSelector(deviceSelector);

    useEffect(() => {
        if (sliderData.isFetch === false) {
            return dispatch(
                fetchSlider()
            )
        }
    }, [sliderData.data])


    return (
        <div style={{ width: '100%', height: 'auto' }}>
            {sliderData.isFetch === true && 
                <Carousel autoplay={true} className="slideshow" >
                    {
                        sliderData.data && sliderData.data.map((slider, y) => {
                            const content = (deviceData.device === 'mobile' && deviceData.orientation === 'portrait') ?
                                <PhotoDisplayHandler
                                    img_name={slider.img_mobile}
                                    dir="sliders"
                                    calculate={false}
                                    img_class_name="slideshow"
                                />
                                :
                                <PhotoDisplayHandler
                                    img_name={slider.img_desktop}
                                    dir="sliders"
                                    calculate={true}
                                    img_class_name="slideshow"
                                />;

                            return (
                                slider.url == null ?
                                    <div key={y} >
                                        {content}
                                    </div>
                                    :
                                    <div key={y}>
                                        <a href={slider.url} target="_blank">
                                            {content}
                                        </a>
                                    </div>
                            );

                        })
                    }


                </Carousel>
            }
        </div>
    );
}

export default SlideshowAd;
