import React from "react"
// import { HeartOutlined, BarChartOutlined } from '@ant-design/icons';
import './ProductCard.css';

import { PhotoDisplayHandler} from "../my_components"

const ProductCard = ({ product }) => {

    return (
        <div className="product_card">
            <a href={`/proizvod/${product.slug}`} >
                <h3 className="product_card_title">{product.pt_name}</h3>
            </a>
            <a href={`/proizvod/${product.slug}`} >
                <div className="product_card_image_box">
                    <PhotoDisplayHandler
                        img_name={product.bg_img_name}
                        dir="products"
                        calculate={true}
                        img_class_name="product_card_image_box_image"
                        img_alt={product.product_name}
                    />
                </div>
            </a>
            <a href={`/proizvod/${product.slug}`} >
                <h2 className="product_card_name">{product.product_name}</h2>
            </a>
            <a href={`/proizvod/${product.slug}`} >
                <h3 className="product_card_price">{product.price} KM</h3>
            </a>
            {/* <div className="product_card_buttons">
                    <a ><HeartOutlined  style={{fontSize: '25px'}}/></a>
                    <a ><BarChartOutlined style={{fontSize: '25px'}}/></a>
                </div> */}

        </div>
    );
};


export default ProductCard
