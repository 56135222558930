import React from "react";
import { Col } from "antd";

import { Baners, RecentlyAddedProducts, SlideshowAd } from "../my_components"
import './Home.css';

const Home = () => {

  return (
    <>
      <Col>
        {/* REKLAMA */}
        <SlideshowAd />
      </Col>
      <Col
        className="home_component"
        xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} 
        md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} 
        xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }}
      >
        {/* PARTNERI male ikonice*/}
        {/* <Partners /> */}
        {/* <div className="break"></div>  */}
        {/*  NOVOSTI */}
        <Baners />
        <div className="break"></div> 

        {/* POSLEDNJE DODATI PROIZVODI */}
        <RecentlyAddedProducts />
        <div className="break"></div> 
        
        {/* ISTAKNUTI PROIZVODI */}
        

        {/* NAJPRODAVANIJI PROIZVODI */}


        {/* <Row>Cenometar</Row> */}

        {/* SERVIS I USLUGE */}
        {/* <ServiceAndFavor/> */}
      </Col>
    </>
  )
}

export default Home;
