import {
    PUT_POST,
    FETCH_POST,
    PUT_INDIVIDUAL_POST,
    FETCH_INDIVIDUAL_POST
} from './constants';

export const fetchPost = payload => ({
    type: FETCH_POST,
    payload
});

export const putPost = payload => ({
    type: PUT_POST,
    payload
});

export const fetchIndividualPost = payload => ({
    type: FETCH_INDIVIDUAL_POST,
    payload
});

export const putIndividualPost = payload => ({
    type: PUT_INDIVIDUAL_POST,
    payload
});

