import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col } from "antd";
import { goToTop, truncateString } from "../../utils";
import PhotoDisplayHandler from "../general/PhotoDisplayHandler";
import './Post.css';

const PostAndPartnerCard = ({ item, current }) => {
    const history = useHistory();
    return (
        <div className="post_card" id={item.slug} onClick={() => {
            goToTop()
            history.push(`/${current}/${item.slug}`)
        }}>
            <Row >
                <Col className="post_card_col1">
                    <PhotoDisplayHandler
                        img_name={item.img_name}
                        dir="posts"
                        calculate={true}
                        img_class_name="post_card_picture"
                        img_alt={item.post_title}

                    />
                </Col>
                <Col className="post_card_col2">

                    <Row>
                        <h1 className="post_card_title">{item.post_title}</h1>
                    </Row>
                    <Row>
                        <h2 className="post_card_subtitle">{truncateString(item.post_description, 50)}</h2>
                    </Row>
                    <Row>
                        <h2 className="post_card_date">{item.created_at}</h2>
                    </Row>


                </Col>
            </Row>
        </div>
    );
}

export default PostAndPartnerCard;