import { take, put, call } from 'redux-saga/effects'
import { FETCH_CATEGORIES } from './constants'
import { putCategories} from './actions'
import apiCall, { API_PATHS } from '../../services/ApiCall';

export function* fetchCategoriesFunction() {
    const { payload } = yield take(FETCH_CATEGORIES)
    yield put(putCategories({'isFetch': false}))
    const data = yield call(apiCall, 'GET', API_PATHS.API_CATEGORIES, payload);
    yield put(putCategories({
        'data': data.data.data,
        'isFetch': true
    }))
}
